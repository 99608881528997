import { ThemeProvider } from '@mui/material/styles';
import classNames from 'classnames';
import { FC } from 'react';

import NoDataIcon from '../../Icons/components/NoData';
import { useTheme } from '../../theme';

import { DEFAULT_NO_DATA_TEXT } from './NoData.constants';
import { NoDataStyled } from './NoData.styled';
import { TNoDataProps } from './NoData.types';

export const NoData: FC<TNoDataProps> = ({
  className = '',
  height,
  label = DEFAULT_NO_DATA_TEXT,
}) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <NoDataStyled
        height={height}
        className={classNames(['NoData', className])}
        data-test-id="rui__no-data__wrapper"
      >
        <NoDataIcon size={64} role="presentation" />
        <div>{label}</div>
      </NoDataStyled>
    </ThemeProvider>
  );
};
