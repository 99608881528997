import { useHistory } from 'react-router-dom';

import { Routes } from 'constants/index';

import { Container, LinkStyled, Version } from './styled';

const Header = () => {
  const history = useHistory();
  const isInsideRun = !!window.runUIhistoryContainer;
  const pageName = history.location.pathname.split('/')[2];

  return !isInsideRun ? (
    <Container>
      <div>
        <div>Workflow UI</div>
        <Version>{`version ${process?.env?.REACT_APP_VERSION}`}</Version>
      </div>
      <div>
        <LinkStyled
          to={Routes.APPROVALS}
          pageName={pageName === Routes.APPROVALS.split('/')[2]}
        >
          Approvals
        </LinkStyled>
        <LinkStyled
          to={Routes.EXECUTIONS}
          pageName={pageName === Routes.EXECUTIONS.split('/')[2]}
        >
          Executions
        </LinkStyled>
        <LinkStyled
          to={Routes.WORKFLOWS}
          pageName={pageName === Routes.WORKFLOWS.split('/')[2]}
        >
          List
        </LinkStyled>
        <LinkStyled
          to={Routes.HISTORY}
          pageName={pageName === Routes.HISTORY.split('/')[2]}
        >
          History
        </LinkStyled>
        <LinkStyled
          to={Routes.APPROVALS_GROUP}
          pageName={pageName === Routes.APPROVALS_GROUP.split('/')[2]}
        >
          Approvals Group
        </LinkStyled>
      </div>
    </Container>
  ) : null;
};

export default Header;
