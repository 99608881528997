import { TableHeaderProps } from 'react-table';

import { THorizontalAlign } from 'types';

import { getStyles } from './getStyles';

export const getHeaderProps = (
  props: Partial<TableHeaderProps>,
  {
    column,
  }: {
    column: {
      align: THorizontalAlign;
      className: string;
    };
  },
) => [{ ...props, title: '' }, getStyles(column.align, column.className)];
