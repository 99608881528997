import { createApi } from '@reduxjs/toolkit/query/react';

import { workflowService } from '../../../resources';
import { transformToBaseQueryResponse } from '../../../utils';

export const workflowByHashSumApi = createApi({
  reducerPath: 'workflowByHashSumQuery',
  tagTypes: ['WorkflowByHashSum'],
  baseQuery: async (args) => {
    return transformToBaseQueryResponse(
      await workflowService.fetchWorkflowByHashSum(args),
    );
  },
  endpoints: (build) => ({
    getWorkflowByHashSum: build.query({
      query: (hashSum: string) => hashSum,
      providesTags: ['WorkflowByHashSum'],
    }),
  }),
});

export const { useGetWorkflowByHashSumQuery } = workflowByHashSumApi;
