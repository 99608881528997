export const DISPLAYED_COLUMN_KEYS = [
  'workflow',
  'create_time',
  'author',
  'approvers',
  'info',
];

export const PAGE_SIZE = 20;

export const PAGE_SIZES = [10, 20, 50];

export const WORKFLOW_OPTION_RESET = { value: '', label: 'Reset' };
