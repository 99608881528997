/* eslint-disable import/no-mutable-exports */

import { AccumMarketValueService } from './accumMarketValue';
import { ApprovalGroupService } from './approvalGroup';
import { ApprovalSettingService } from './approvalSetting';
import { CurrencyService } from './currency';
import { ExecutionService } from './execution';
import { InfluxProxyService } from './influxProxy';
import { UsersService } from './users';
import { WorkflowService } from './workflow';
import { WorkflowParamsService } from './workflow-params';

let accumMarketValueService: AccumMarketValueService;
let executionService: ExecutionService;
let workflowService: WorkflowService;
let approvalGroupService: ApprovalGroupService;
let approvalSettingService: ApprovalSettingService;
let usersService: UsersService;
let currencyService: CurrencyService;
let influxProxyService: InfluxProxyService;
let workflowParamsService: WorkflowParamsService;

export function initResources() {
  accumMarketValueService = new AccumMarketValueService();
  executionService = new ExecutionService();
  workflowService = new WorkflowService();
  approvalGroupService = new ApprovalGroupService();
  approvalSettingService = new ApprovalSettingService();
  usersService = new UsersService();
  currencyService = new CurrencyService();
  influxProxyService = new InfluxProxyService();
  workflowParamsService = new WorkflowParamsService();
}

export {
  accumMarketValueService,
  executionService,
  workflowService,
  approvalGroupService,
  approvalSettingService,
  usersService,
  currencyService,
  influxProxyService,
  workflowParamsService,
};
