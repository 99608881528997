import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Router, BrowserRouter, Route, Switch } from 'react-router-dom';
import { NotificationProvider } from 'react-ui-kit-exante';

import Header from 'components/Header';
import {
  WorkflowDetailsPage,
  ApprovalsPage,
  ExecutionDetailsPage,
  ExecutionsPage,
  HistoryPage,
  WorkflowListPage,
  ApprovalsGroupPage,
  AddApprovalGroupPage,
  AddApprovalSettingPage,
} from 'pages';
import { getBaseName } from 'utils';

import ErrorBoundary from './components/ErrorBoundary';
import { Routes } from './constants';
import useStoreRequests from './hooks/useStoreRequests';

dayjs.extend(utc);

const App = () => {
  useStoreRequests();

  const isInsideRun = !!window.runUIhistoryContainer;
  const baseName = getBaseName();

  const InnerRouterComponent = (
    <div className="App">
      <Header />
      <Switch>
        <Route exact path={Routes.APPROVALS}>
          <ApprovalsPage />
        </Route>
        <Route exact path={Routes.APPROVALS_DETAILS}>
          <ExecutionDetailsPage />
        </Route>
        <Route exact path={Routes.EXECUTIONS}>
          <ExecutionsPage />
        </Route>
        <Route exact path={Routes.EXECUTIONS_DETAILS}>
          <ExecutionDetailsPage />
        </Route>
        <Route exact path={Routes.WORKFLOWS}>
          <WorkflowListPage />
        </Route>
        <Route exact path={Routes.WORKFLOW_DETAILS}>
          <WorkflowDetailsPage />
        </Route>
        <Route exact path={Routes.HISTORY}>
          <HistoryPage />
        </Route>
        <Route exact path={Routes.APPROVALS_GROUP}>
          <ApprovalsGroupPage />
        </Route>
        <Route exact path={Routes.ADD_GROUP}>
          <AddApprovalGroupPage />
        </Route>
        <Route exact path={Routes.ADD_SETTINGS}>
          <AddApprovalSettingPage />
        </Route>
      </Switch>
    </div>
  );

  const routes = isInsideRun ? (
    <Router history={window.runUIhistoryContainer}>
      {InnerRouterComponent}
    </Router>
  ) : (
    <BrowserRouter basename={baseName}>{InnerRouterComponent}</BrowserRouter>
  );
  return (
    <ErrorBoundary>
      <NotificationProvider />
      {routes}
    </ErrorBoundary>
  );
};

export default App;
