import { Notification } from 'react-ui-kit-exante';

import { getErrorMessage } from '../../utils';

import { usersMappers } from './users.mappers';
import { UsersRepository } from './users.repository';

export class UsersService {
  public async fetchUsers() {
    try {
      const { data } = await UsersRepository.fetchUsers();

      return usersMappers(data);
    } catch (err) {
      Notification.error({
        title: getErrorMessage(err, 'While fetching users occurred error'),
      });

      return [];
    }
  }
}
