import { ThemeProvider } from '@mui/material/styles';
import { FC } from 'react';

import { useTheme } from '../../theme';

import { ITooltipProps } from './Tooltip.types';
import { StyledTooltip, StyledTooltipTextWrapper } from './styled';

export const Tooltip: FC<ITooltipProps> = ({
  placement = 'bottom',
  title,
  sx = null,
  children,
  arrow = true,
  dataTestId,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledTooltip
        className="Tooltip"
        data-test-id="tooltip-overlay"
        title={title}
        placement={placement}
        componentsProps={{ tooltip: { sx } }}
        arrow={arrow}
        {...rest}
      >
        {/*
          The child root is required to be the DOM element.
          It also can forward the ref to some DOM element inside it.

          This extra span wrapper allows to render the Tooltip properly
          even if the child component does not forward a ref.

          https://mui.com/material-ui/react-tooltip/#custom-child-element
         */}
        <StyledTooltipTextWrapper
          className="TooltipTextWrapper"
          data-test-id={dataTestId || 'tooltip-text-wrapper'}
        >
          {children}
        </StyledTooltipTextWrapper>
      </StyledTooltip>
    </ThemeProvider>
  );
};
