import { isEqual, isUndefined, omit, omitBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { TGetDefaultFilters } from '../types';

interface IUseFiltersProps {
  getDefaultFilters?: TGetDefaultFilters;
  initialState?: Record<string, unknown>;
}

// it needed to avoid rerender
const DEFAULT_FILTERS_STATE = {};

export function useFilters({
  getDefaultFilters,
  initialState,
}: IUseFiltersProps) {
  const defaultFilters = useMemo(
    () => (getDefaultFilters ? getDefaultFilters() : DEFAULT_FILTERS_STATE),
    [getDefaultFilters],
  );
  // TODO: state should be object. Don't know how to type correctly this case with generics
  const [filters, setFilters] = useState<Record<string, unknown>>(
    initialState || defaultFilters,
  );

  const handleFilterChange = useCallback((column, value) => {
    setFilters((prev) => {
      // Case: If new filter was chosen It shouldn't be saved in state
      if (!prev[column] && value === '') {
        return prev;
      }

      const newFilters = { ...prev, [column]: value };

      if (!isEqual(prev, newFilters)) {
        return newFilters;
      }

      return prev;
    });
  }, []);

  const handleRemoveFilter = useCallback(
    (column: string) =>
      setFilters((prev) => {
        const prevFiltersWithoutEmptyValues = omitBy(prev, (value) =>
          isUndefined(value),
        );

        const prevFiltersHaveRemovalColumn = Object.keys(prev).some(
          (key) => key === column,
        );

        const newFilters = prevFiltersHaveRemovalColumn
          ? omit(prev, [column])
          : prevFiltersWithoutEmptyValues;

        return isEqual(newFilters, prevFiltersWithoutEmptyValues)
          ? prev
          : newFilters;
      }),
    [defaultFilters],
  );
  const resetFilters = useCallback(
    () => setFilters(defaultFilters),
    [defaultFilters],
  );

  return {
    filters,
    setFilter: handleFilterChange,
    resetFilters,
    removeFilter: handleRemoveFilter,
  };
}
