import { isFunction, isBoolean, isObject, get } from 'lodash';
import { CSSProperties, memo } from 'react';

import { useTheme } from '../../../theme';
import { RowStyled } from '../Table.styled';
import { closeSubRows, getCellPropsWithStyles } from '../helpers';

import { TableCell } from './TableCell';
import { TableRowProps } from './types';

export const TableRowComponent = <Data extends object>({
  expanded,
  id: rowId,
  displaysOfTreeLines,
  isSelected,
  singleColored,
  rowProps,
  handleRowClick,
  handleCellClick,
  cells,
  getCellPropsExternal,
  getColumnPropsExternal,
  index,
  tableState,
  values,
  style,
  allColumns,
  ...rest
}: TableRowProps<Data>) => {
  const theme = useTheme();
  const STYLE: CSSProperties = {
    ...style,
    ...(rowProps?.style || {}),
  };

  const getCellProps = (cell: (typeof cells)[number]) =>
    cell.getCellProps(
      getCellPropsWithStyles([
        getCellPropsExternal(cell, theme),
        getColumnPropsExternal(cell.column, theme),
      ]),
    );

  const rowClickHandler = () => {
    if (handleRowClick) {
      handleRowClick(values, index);
    }
  };

  const cellClickHandler = (cell: (typeof cells)[number]) => {
    const { row, column } = cell;

    const expandTable =
      (isBoolean(expanded) && expanded) ||
      (isObject(expanded) && !expanded.customExpandControl);

    if (expandTable && row.canExpand) {
      const shouldExpand = isFunction(column.shouldExpand)
        ? column.shouldExpand({ ...cell, tableState })
        : column.shouldExpand ?? true;

      if (shouldExpand) {
        if (row.isExpanded) {
          closeSubRows(row.subRows);
        }

        row.toggleRowExpanded();
      }
    }

    if (handleCellClick) {
      handleCellClick({
        ...cell,
        tableState,
      });
    }
  };

  return (
    <RowStyled
      data-test-id="table__body--row"
      data-row-id={rowId}
      data-name="table__body--row"
      onClick={rowClickHandler}
      isColoredRow={!singleColored && !isSelected}
      isSelectedRow={isSelected}
      isClickable={Boolean(handleRowClick)}
      {...rowProps}
      style={STYLE}
    >
      {cells.map((cell, indexCell) => (
        <TableCell<Data>
          cellProps={getCellProps(cell)}
          displaysOfTreeLines={displaysOfTreeLines}
          expanded={expanded}
          id={cell.column.id as keyof Data}
          isFirstInRow={indexCell === 0}
          key={`${rowId}-${cell.column.id}`}
          render={cell.render}
          row={rest}
          value={cell.value}
          handleCellClick={() => cellClickHandler(cell)}
          tooltip={cell.column.tooltip}
          tableState={tableState}
          cellStyle={{ ...(get(allColumns, `${indexCell}.cellStyle`) || {}) }}
        />
      ))}
    </RowStyled>
  );
};

export const TableRow = memo(TableRowComponent) as typeof TableRowComponent;
