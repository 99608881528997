import { ThemeProvider } from '@mui/material/styles';
import { CSSProperties, memo } from 'react';

import { useTheme } from '../../../theme';

import { Skeleton } from './Skeleton';
import {
  RootStyled,
  RowStyled,
  CellStyled,
  TableContainerStyled,
  TableBodyStyled,
} from './TableSkeleton.styled';
import { getRows } from './helpers';
import { ITableSkeletonProps } from './types';

export const TableSkeletonComponent = <Data extends object>({
  columns,
  isFlexLayout,
  skeletonsCount,
}: ITableSkeletonProps<Data>) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <RootStyled>
        <TableContainerStyled>
          <TableBodyStyled>
            {getRows(skeletonsCount).map((row) => (
              <RowStyled key={row} data-test-id="skeleton-row">
                {columns.map((column) => {
                  const headerProps = column.getHeaderProps();
                  const additionalStyles: CSSProperties =
                    headerProps?.style || {};

                  return (
                    <CellStyled
                      key={`${row}-${column?.id}`}
                      isFlexLayout={isFlexLayout}
                      style={additionalStyles}
                      data-test-id="skeleton-cell"
                    >
                      <Skeleton width="auto" />
                    </CellStyled>
                  );
                })}
              </RowStyled>
            ))}
          </TableBodyStyled>
        </TableContainerStyled>
      </RootStyled>
    </ThemeProvider>
  );
};

export const TableSkeleton = memo(
  TableSkeletonComponent,
) as typeof TableSkeletonComponent;
