import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Table, useTableData } from 'react-ui-kit-exante';

import { Routes } from '../../../constants';
import { useLogHandleTime, usePrevious } from '../../../hooks';
import { approvalSettingService } from '../../../resources';
import { IApprovalGroup, IApprovalSetting, IOption } from '../../../types';
import { prepareTableId } from '../../../utils';

import styles from './Settings.module.css';
import { getColumns } from './columns';
import { DISPLAYED_COLUMN_KEYS, PAGE_SIZE, PAGE_SIZES } from './constants';
import { isEmptyField } from './helpers';

const tableId = prepareTableId('approvalSetting');

interface ISettingsProps {
  groupData: IApprovalGroup[];
  usersOptions: IOption[];
}

export const Settings = ({ groupData, usersOptions }: ISettingsProps) => {
  const history = useHistory();

  const { logHandleTime, setStartHandleTime } = useLogHandleTime(
    'approval-setting-page',
  );

  const getApprovalSettings = useCallback(() => {
    setStartHandleTime();

    return approvalSettingService.fetchApprovalSettings();
  }, [setStartHandleTime]);

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getApprovalSettings },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getApprovalSettings, tableId],
  );

  const {
    data,
    isLoading,
    fetchData: refetch,
  } = useTableData<IApprovalSetting[]>(tableDataArgs);

  const groupOptions = groupData?.map((item: { name: string }) => {
    const { name } = item;

    return { value: name, label: name };
  });

  const columns = useMemo(
    () => getColumns(groupOptions, usersOptions),
    [groupOptions, usersOptions],
  );

  const handleAddApprovalSetting = useCallback(() => {
    history.push(Routes.ADD_SETTINGS, {
      groupOptions,
      usersOptions,
    });
  }, [history, groupOptions, usersOptions]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'AddSetting',
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add setting"
            iconSize={24}
            onClick={handleAddApprovalSetting}
          />
        ),
      },
    ],
    [handleAddApprovalSetting],
  );

  const handleSave = useCallback(
    async (
      _: IApprovalSetting,
      {
        id,
        approvers,
        currency,
        approvalGroup,
        approvalThreshold,
        emails,
      }: IApprovalSetting,
    ) => {
      if (isEmptyField(approvers, 'approvers')) {
        await refetch();

        return;
      }

      await approvalSettingService.updateApprovalSetting({
        id,
        approvers,
        currency,
        approvalGroup,
        approvalThreshold,
        emails: Array.isArray(emails)
          ? emails
          : emails?.replace(/,/g, ', ').split(', '),
      });
      await refetch();
    },
    [],
  );

  const handleDelete = useCallback(async (value: IApprovalSetting) => {
    if (value?.id) {
      await approvalSettingService.deleteApprovalSetting(value.id);
    }

    await refetch();
  }, []);

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: handleSave,
      additionalActions: [
        {
          label: (
            <IconButton
              iconColor="radical"
              iconName="DeleteIcon"
              iconSize={16}
            />
          ),
          title: 'Delete',
          onClick: handleDelete,
        },
      ],
    }),
    [handleSave, handleDelete],
  );

  const previousSettingData = usePrevious(data);

  useEffect(() => {
    if (data && !isEqual(previousSettingData, data)) {
      logHandleTime();
    }
  }, [data, logHandleTime, previousSettingData]);

  return (
    <Table
      additionalActions={additionalActions}
      className={styles.SettingTable}
      columns={columns}
      data={data || []}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      pageSize={PAGE_SIZE}
      pageSizes={PAGE_SIZES}
      rowActions={rowActions}
      saveColumnOrder
      showTableInfo
      tableId={tableId}
      title="Approvals Setting"
    />
  );
};
