import { WfType } from 'constants/index';

export const DISPLAYED_COLUMN_KEYS = [
  'name',
  'ttl',
  'version',
  'active',
  'author',
  'create_time',
  'actions',
];
export const PAGE_SIZE = 20;
export const PAGE_SIZES = [10, 20, 50];
export const EDITABLE_WORKFLOW_LIST = [
  WfType.MANUAL_TRADE,
  WfType.MANUAL_TRANSACTION,
  WfType.BULK_MANUAL_TRANSACTION,
];
export const MAX_AVAILABLE_DAYS = 100;
export const TTL_ACTION = 'decline';
