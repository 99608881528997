import { Radio as RadioMui } from '@mui/material';
import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../../helpers/blockNonNativeProps';

export const RadioStyled = styled(RadioMui, {
  shouldForwardProp: blockNonNativeProps('warning'),
})(() => ({
  padding: '0 7px 0 0',
}));
