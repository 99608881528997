import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { IconButton, Input, Panel } from 'react-ui-kit-exante';

import { Routes } from '../../constants';
import { approvalGroupService } from '../../resources';
import { ICreateApprovalGroupPayload } from '../../types';

import AddApprovalGroupStyles from './AddApprovalGroup.module.css';
import { controlCls, defaultValues } from './constants';
import { validationSchema } from './validationSchema';

export const AddApprovalGroupPage = () => {
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push(Routes.APPROVALS_GROUP);
  }, [history]);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
  } = methods;

  const onSubmit = async (payload: ICreateApprovalGroupPayload) => {
    await approvalGroupService.createApprovalGroup(payload);
    handleClose();
  };

  const renderActions = () => (
    <div className={AddApprovalGroupStyles.Actions}>
      <IconButton
        disabled={!isDirty}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={24}
        label="Save"
        type="submit"
      />
      <IconButton
        iconColor="secondary"
        iconName="CloseIcon"
        iconSize={24}
        onClick={handleClose}
      />
    </div>
  );

  const controls = (
    <div className={AddApprovalGroupStyles.Controls}>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            label="Name"
            sx={controlCls}
            warning={dirtyFields.name}
            error={Boolean(errors.name)}
            message={errors.name?.message}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            sx={controlCls}
            label="Description"
            warning={dirtyFields.description}
            error={Boolean(errors.description)}
            message={errors.description?.message}
          />
        )}
      />
    </div>
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={AddApprovalGroupStyles.Wrapper}
    >
      <Panel title="Create Approval group" action={renderActions()} />
      {controls}
    </form>
  );
};
