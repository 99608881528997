import { createSlice } from '@reduxjs/toolkit';

import { EStatus } from 'store/types';

import { initialState } from './constants';
import { fetchWorkflow } from './workflow.actions';

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWorkflow.pending, (state) => ({
      ...state,
      status: EStatus.Loading,
      error: null,
    }));

    builder.addCase(fetchWorkflow.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      status: EStatus.Succeeded,
    }));

    builder.addCase(fetchWorkflow.rejected, (state, action) => ({
      ...state,
      error: action.payload || null,
      status: EStatus.Failed,
    }));
  },
});
