import { formatNumber, Skeleton } from 'react-ui-kit-exante';

import { DEFAULT_POOLING_INTERVAL_DATA_20 } from '../../../constants';
import { useGetAccumMarketValueQuery } from '../../../store/accumMarketValue';
import { IUseQueryReturn, TAccumulationMarketValue } from '../../../types';

import MarketValueStyles from './MarketValue.module.css';

export const MarketValue = () => {
  const { data, isLoading, isFetching } = useGetAccumMarketValueQuery<
    IUseQueryReturn<TAccumulationMarketValue>
  >(
    {
      workflow_execution_ids: [],
    },
    {
      pollingInterval: DEFAULT_POOLING_INTERVAL_DATA_20,
    },
  );

  const isInitialLoad = !data && (isLoading || isFetching);

  return (
    <div className={MarketValueStyles.Wrapper}>
      <div className={MarketValueStyles.Value}>
        {isInitialLoad ? (
          <Skeleton
            width={140}
            height={20}
            variant="rounded"
            sx={{ marginBottom: '3px' }}
          />
        ) : (
          formatNumber(data?.market_value || 0)
        )}
      </div>
      <div className={MarketValueStyles.Label}>Market Value, EUR</div>
    </div>
  );
};
