import { SkeletonProps } from '@mui/material/Skeleton/Skeleton';
import { ThemeProvider } from '@mui/material/styles';

import { useTheme } from '../../theme';

import { StyledSkeletonMUI } from './Skeleton.styled';

export const Skeleton = ({
  animation = 'wave',
  variant = 'rounded',
  ...props
}: SkeletonProps) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledSkeletonMUI {...props} variant={variant} animation={animation} />
    </ThemeProvider>
  );
};
