import {
  IndeterminateCheckbox,
  IndeterminateCheckboxHead,
} from '../components/IndeterminateCheckboxes';

export const addExtraColumn = (hooks, bulkActions) =>
  hooks.visibleColumns.push((allColumns) => [
    {
      id: 'selectionBulk',
      width: 65,
      minWidth: 65,
      hideInMenu: true,
      disableFilters: true,
      Header: (header) => {
        return (
          <IndeterminateCheckboxHead
            header={header}
            bulkActions={bulkActions}
          />
        );
      },
      Cell: ({ row }) => (
        <IndeterminateCheckbox
          row={row}
          bulkActions={bulkActions}
          {...row.getToggleRowSelectedProps({ title: '' })}
        />
      ),
    },
    ...allColumns,
  ]);
