interface IUsersProps {
  username: string;
}

export function usersMappers(users: IUsersProps[]) {
  return users.map(({ username }) => ({
    label: username,
    value: username,
  }));
}
