import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';

export const SortableListItemActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  opacity: 0,
}));

export const StyledListItemButton = styled(ListItemButton)(() => ({
  '&:hover > div': {
    opacity: 1,
  },
}));
