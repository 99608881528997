import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useCallback, useEffect, useState } from 'react';
import { useIMask } from 'react-imask';

import { useTheme } from '../../../../theme';
import { Input } from '../../Input/Input';

import {
  BLOCK_RANGE_HOURS,
  BLOCK_RANGE_MINUTES,
  BLOCK_RANGE_SECONDS,
} from './constants';
import { TimePickerProps } from './types';

export const TimePicker = ({
  size,
  value,
  onAccept,
  onChange,
  inputFormat = 'HH:MM:SS',
  ...rest
}: TimePickerProps) => {
  const theme = useTheme();
  const [initialValue, setInitialValue] = useState(value);

  const handleAccept = useCallback(
    (result: string) => {
      if (onAccept) {
        onAccept(result);
      }
    },
    [onAccept],
  );

  const { ref, setValue, typedValue } = useIMask(
    {
      lazy: true,
      overwrite: true,
      autofix: true,
      mask: inputFormat,
      blocks: {
        HH: BLOCK_RANGE_HOURS,
        MM: BLOCK_RANGE_MINUTES,
        ...(inputFormat === 'HH:MM:SS' && BLOCK_RANGE_SECONDS),
      },
    },
    { onAccept: handleAccept, onComplete: onChange },
  );

  useEffect(() => {
    setInitialValue(typedValue);
  }, [typedValue]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Input
          ref={ref}
          size={size}
          placeholder={inputFormat}
          value={typedValue || initialValue}
          iconRight={<AccessTimeIcon />}
          onChange={(event) => setValue(event.target.value)}
          {...rest}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

TimePicker.displayName = 'TimePicker';
