import { Notification } from 'react-ui-kit-exante';

import { IApprovalGroup } from 'types';

import { FieldName } from './constants';

export const isEmptyField = (
  values: IApprovalGroup,
  fieldName: FieldName.NAME | FieldName.DESCRIPTION,
) => {
  if (!values[fieldName]?.trim()) {
    Notification.error({
      title: `The field "${fieldName.toUpperCase()}" cannot be empty`,
    });

    return true;
  }

  return false;
};
