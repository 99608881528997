import { Notification } from 'react-ui-kit-exante';

import { IOption } from '../../types';
import { getErrorMessage } from '../../utils';

import { currencyMappers } from './currency.mappers';
import { CurrencyRepository } from './currency.repository';

export class CurrencyService {
  public async fetchCurrencies(): Promise<IOption[]> {
    try {
      const { data } = await CurrencyRepository.fetchCurrencies();

      return currencyMappers(data);
    } catch (err) {
      Notification.error({
        title: getErrorMessage(err, 'While fetching currencies occurred error'),
      });

      return [];
    }
  }
}
