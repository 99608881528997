import { MenuItem as MenuItemMUI } from '@mui/material';
import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../helpers';
import { Input as RUIInput } from '../Input/Input';

import { IMenuItemProps } from './types';

interface IInputProps {
  size?: string;
}

export const Input = styled(RUIInput)<IInputProps>(({ size, theme }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    ...(size === 'small' && {
      minHeight: '16px',
      fontSize: '12px',
      lineHeight: '16px',
    }),
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&:hover': {
      backgroundColor: theme?.color.input.bg.hover,
    },
  },
  '& .MuiSelect-icon': {
    right: 15,
    color: theme.color.typo.secondary,
  },
}));

export const IconWrapper = styled('span')(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: '8px',
  maxWidth: '16px',
  maxHeight: '16px',
}));

export const MenuItem = styled(MenuItemMUI, {
  shouldForwardProp: blockNonNativeProps('size'),
})<IMenuItemProps>(({ size, theme }) => ({
  fontFamily: theme.font.main,
  fontSize: theme.size.text.lg,
  lineHeight: '24px',
  color: theme.color.typo.secondary,
  ...(size === 'small' && {
    padding: '8px',
  }),
  ...(size === 'medium' && {
    padding: '8px 12px 8px',
  }),
  '&.Mui-selected': {
    backgroundColor: theme?.color.dropdown.list.bg.focus,
    color: theme?.color.typo.primary,
    '&:hover': {
      backgroundColor: theme?.color.dropdown.list.bg.hover,
    },
  },
  '&:hover': {
    backgroundColor: theme?.color.dropdown.list.bg.hover,
    color: theme?.color.typo.primary,
  },
  '&:active': {
    color: theme?.color.typo.promo,
    backgroundColor: theme?.color.dropdown.list.bg.focus,
  },
})) as React.ComponentType<IMenuItemProps>;
