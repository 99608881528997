import { styled } from '@mui/material/styles';
import { renderToString } from 'react-dom/server';

import { CloseIcon } from '../../../Icons';
import { IconProps } from '../../../Icons/types';
import { Input } from '../Input/Input';

// todo after update react to actual version remove this code on more optimazed https://react.dev/reference/react-dom/server/renderToString#removing-rendertostring-from-the-client-code
function getSvgString(Icon: (allProps: IconProps) => JSX.Element) {
  const div = document.createElement('div');
  div.innerHTML = renderToString(<Icon size={12} />);

  // remove span with saving svg inside
  const firstSpan = div.querySelector('span');
  while (firstSpan.firstChild) {
    firstSpan.parentNode.insertBefore(firstSpan.firstChild, firstSpan);
  }
  firstSpan.parentNode.removeChild(firstSpan);

  return div.innerHTML;
}

export const SearchInputStyled = styled(Input)(() => ({
  '& input::-webkit-search-cancel-button': {
    position: 'relative',
    cursor: 'pointer',
    appearance: 'none',
    height: '16px',
    width: '16px',
    color: 'black',
    backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(
      getSvgString(CloseIcon),
    )}")`,
  },
}));
