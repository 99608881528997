import { createEndDate } from './createEndDate';
import { createStartDate } from './createStartDate';
import { createStringDateWithoutTimezone } from './createStringDateWithoutTimezone';

export function setStartEndForDateRangeString(dates: string[]) {
  if (!dates) {
    return [undefined, undefined];
  }

  const [minDate, maxDate] = dates;
  let maxDateClone = maxDate;

  if (new Date(maxDate).getFullYear() === 1970) {
    maxDateClone = new Date().toISOString().slice(0, 16);
  }

  return [
    minDate
      ? createStringDateWithoutTimezone(createStartDate(minDate))
      : undefined,
    maxDate
      ? createStringDateWithoutTimezone(createEndDate(maxDateClone))
      : undefined,
  ];
}
