import { getUsernameFromCookie } from 'utils';

const DEFAULT_VALUE = '';

export const getUsername = () => {
  try {
    const usernameFromCookie = getUsernameFromCookie();

    if (usernameFromCookie) {
      return usernameFromCookie;
    }

    const persistRoot = localStorage.getItem('persist:root');

    if (!persistRoot) {
      console.error('Persist root not found');

      return DEFAULT_VALUE;
    }

    const {
      info: { userName: usernameFromLocalStorage },
    } = JSON.parse(JSON.parse(persistRoot).user);

    if (usernameFromLocalStorage) {
      return usernameFromLocalStorage;
    }
  } catch (err) {
    console.error(err);
  }

  return DEFAULT_VALUE;
};
