import type { TLogHandleTimeRequest } from 'types';

import { InfluxProxyRepository } from './influxProxy.repository';

export class InfluxProxyService {
  public async postLogHandleTime(data: TLogHandleTimeRequest) {
    try {
      InfluxProxyRepository.fetchLogHandleTime(data);
    } catch (error) {
      console.error(error);
    }
  }
}
