import { useCallback, useMemo } from 'react';
import { useTableData } from 'react-ui-kit-exante';

import { useLogHandleTime } from '../../hooks';
import { approvalGroupService } from '../../resources';
import { useGetUsersQuery } from '../../store/users';
import { IApprovalGroup, IOption, IUseQueryReturn } from '../../types';
import { prepareTableId } from '../../utils';

import { Groups } from './Groups';
import { Settings } from './Settings';

const tableId = prepareTableId('approvalGroup');

export const ApprovalsGroupPage = () => {
  const { data: usersOptions } =
    useGetUsersQuery<IUseQueryReturn<IOption[]>>(null);

  const { logHandleTime, setStartHandleTime } = useLogHandleTime(
    'approval-group-page',
  );

  const getApprovalGroups = useCallback(() => {
    setStartHandleTime();

    return approvalGroupService.fetchApprovalGroups();
  }, [setStartHandleTime]);

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getApprovalGroups },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getApprovalGroups, tableId],
  );

  const {
    data,
    isLoading,
    fetchData: refetch,
  } = useTableData<IApprovalGroup[]>(tableDataArgs);

  return (
    <>
      <Groups
        data={data}
        isLoading={isLoading}
        logHandleTime={logHandleTime}
        refetch={refetch}
        tableId={tableId}
      />
      <Settings groupData={data} usersOptions={usersOptions || []} />
    </>
  );
};
