import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Panel } from 'react-ui-kit-exante';

import { useInterval } from 'hooks/useInterval';

import { DEFAULT_POOLING_INTERVAL_DATA_5, WfType } from '../../constants';
import { executionService } from '../../resources';
import { executionApi, useGetExecutionQuery } from '../../store/execution';
import {
  IExecutionDetails,
  IUseQueryReturn,
  TUpdateStepComment,
} from '../../types';

import { BulkTransactionsSkeleton, BulkTransactions } from './BulkTransactions';
import { ExecutionContainer, ExecutionSkeleton } from './ExecutionContainer';
import { WorkflowNotFound } from './styled';

export const ExecutionDetailsPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [restartWorkflowExecuted, setRestartWorkflowExecuted] = useState(false);
  const { executionId } = useParams<{ executionId: string }>();
  const { state } = useLocation();

  const { locationWfType, locationWorkflow } = state || {};

  const { data, isLoading, isFetching, refetch } =
    useGetExecutionQuery<IUseQueryReturn<IExecutionDetails>>(executionId);

  const onApproveExecution = (value: string) => {
    const payload = {
      workflow_execution_ids: [value],
    };
    setLoading(true);

    executionService
      .approveExecution(payload)
      .then(() => {
        dispatch(executionApi.util.invalidateTags(['ExecutionDetails']));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeclineExecution = (value: string) => {
    const payload = {
      workflow_execution_ids: [value],
    };
    setLoading(true);

    executionService
      .declineExecution(payload)
      .then(() => {
        dispatch(executionApi.util.invalidateTags(['ExecutionDetails']));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateStepComment = (payload: TUpdateStepComment) => {
    setLoading(true);

    executionService
      .updateStepComment(payload)
      .then(() => {
        dispatch(executionApi.util.invalidateTags(['ExecutionDetails']));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRestartWorkflow = () => {
    setLoading(true);

    executionService
      .restartExecution(executionId)
      .then(() => {
        setRestartWorkflowExecuted(true);
        dispatch(executionApi.util.invalidateTags(['ExecutionDetails']));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useInterval(() => {
    if (restartWorkflowExecuted) {
      refetch();
    }
  }, DEFAULT_POOLING_INTERVAL_DATA_5);

  useEffect(() => {
    if (restartWorkflowExecuted && data?.status !== 'running') {
      setRestartWorkflowExecuted(false);
    }
  }, [data]);

  if (isLoading || isFetching || loading) {
    if (locationWfType === WfType.BULK_MANUAL_TRANSACTION) {
      return <BulkTransactionsSkeleton />;
    }

    return <ExecutionSkeleton />;
  }

  if (!executionId || isEmpty(data)) {
    return (
      <Panel title={locationWorkflow}>
        <WorkflowNotFound>Workflow not found</WorkflowNotFound>
      </Panel>
    );
  }

  const { wf_type: wfType } = data;

  if (wfType === WfType.BULK_MANUAL_TRANSACTION) {
    return (
      <BulkTransactions
        data={data}
        onApproveExecution={onApproveExecution}
        onDeclineExecution={onDeclineExecution}
        onRestartWorkflow={onRestartWorkflow}
      />
    );
  }

  return (
    <ExecutionContainer
      data={data}
      onApproveExecution={onApproveExecution}
      onDeclineExecution={onDeclineExecution}
      onRestartWorkflow={onRestartWorkflow}
      updateStepComment={updateStepComment}
    />
  );
};
