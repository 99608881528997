import { Input } from 'antd';
import { useState, FC } from 'react';
import Cron from 'react-js-cron';

import { Switch } from '../Inputs/Switch/Switch';

import { CRON_FORMAT, InputStatuses } from './constants';
import { CronSchedulerProps } from './types';

import './CronSchedulerStyles.css';

export const CronScheduler: FC<CronSchedulerProps> = (props) => {
  const { value, setValue, isManualInput, error } = props;
  const [isChecked, setIsChecked] = useState(false);

  const errorMessage = error?.description;

  return (
    <div className="wrapper">
      {isManualInput ? (
        <Switch
          label="Manual input"
          checked={isChecked}
          className="toggle-cron"
          onChange={() => setIsChecked(!isChecked)}
        />
      ) : null}
      {isChecked ? (
        <Input
          value={value}
          className="custom-input"
          placeholder={CRON_FORMAT}
          status={error ? InputStatuses.error : undefined}
          onBlur={() => setValue(value)}
          onPressEnter={() => setValue(value)}
          onChange={(event) => setValue(event.target.value)}
        />
      ) : null}
      <Cron {...props} className="my-project-cron" />
      {errorMessage && <p>{`Error: ${errorMessage}`}</p>}
    </div>
  );
};
