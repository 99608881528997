import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  css,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../helpers';
import { IMenuItemStyledComponentProps } from '../Menu.types';

export const StyledSubList = styled(List, {
  shouldForwardProp: blockNonNativeProps(['opened']),
})`
  padding: 0 40px 0 54px;

  .MenuItemButton {
    padding: 4px 0;
    line-height: 1.2;
  }

  .MenuItemTitle {
    font-size: ${({ theme }) => theme.size.text.md};
  }
`;

export const StyledListItemText = styled(ListItemText)`
  margin: 0;
`;

export const StyledExpandMore = styled(ExpandMore)`
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledExpandLess = styled(ExpandLess)`
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledListItem = styled(ListItem, {
  shouldForwardProp: blockNonNativeProps(['opened', 'hasChild']),
})`
  display: block;
  padding: 0;
`;

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: blockNonNativeProps(['active', 'level']),
})<IMenuItemStyledComponentProps>`
  display: flex;
  width: 100%;
  padding: 12px 13px;
  font-size: ${({ theme }) => theme.size.text.lg};
  font-family: ${({ theme }) => theme.font.main};
  font-weight: 400;
  gap: 17px;
  background: none;
  border-left: 2px solid transparent;
  color: ${({ theme }) => theme.color.typo.action};

  &:hover {
    background: none;
    color: ${({ theme }) => theme.color.typo.promo};
  }

  ${({ theme, active, level }) =>
    active &&
    level === 0 &&
    css`
      border-color: ${theme.color.typo.action};
      color: ${theme.color.typo.primary};
      font-weight: 600;

      &:hover {
        color: ${theme.color.typo.primary};
      }
    `}

  ${({ theme, active, level }) =>
    active &&
    level &&
    level > 0 &&
    css`
      color: ${theme.color.typo.primary};
      font-weight: 600;

      &:hover {
        color: ${theme.color.typo.primary};
      }
    `}
`;

export const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: blockNonNativeProps(['active']),
})<IMenuItemStyledComponentProps>`
  min-width: 0;
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.color.typo.secondary};

  // todo ERROR with Component selectors in projects that use ui-kit
  //  Error: Component selectors can only be used in conjunction with @emotion/babel-plugin, the swc Emotion plugin, or another Emotion-aware compiler transform.
  // task https://jira.exante.eu/browse/RUI-262
  .ListItemButton:hover & {
    transform: scale(1.2);
  }

  ${({ theme, active }) =>
    active &&
    css`
      color: ${theme.color.typo.primary};
    `}
`;
