import { createApi } from '@reduxjs/toolkit/query/react';

import { EMPTY_OBJECT } from '../../../constants';
import { executionService } from '../../../resources';
import { transformToBaseQueryResponse } from '../../../utils';

export const executionApi = createApi({
  reducerPath: 'executionQuery',
  tagTypes: ['ExecutionDetails'],
  baseQuery: async (args) => {
    if (!args) {
      return Promise.resolve({ data: EMPTY_OBJECT });
    }

    return transformToBaseQueryResponse(
      await executionService.fetchExecution(args),
    );
  },
  endpoints: (build) => ({
    getExecution: build.query({
      query: (id: string) => id,
      providesTags: ['ExecutionDetails'],
    }),
  }),
});

export const { useGetExecutionQuery } = executionApi;
