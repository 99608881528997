import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { TDefaultTheme, blockNonNativeProps } from 'react-ui-kit-exante';

type ContainerProps = {
  theme?: TDefaultTheme;
};

type ActiveLinkProps = {
  pageName: boolean;
  theme?: TDefaultTheme;
};

export const Container = styled('header')<ContainerProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme?.palette?.success?.main,
  color: theme?.color?.typo?.inverse,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 600,
  justifyContent: 'space-between',
  padding: '15px 24px',
}));

export const Version = styled('span')(() => ({
  fontSize: '12px',
  fontWeight: 500,
}));

export const LinkStyled = styled(Link, {
  shouldForwardProp: blockNonNativeProps(['pageName']),
})<ActiveLinkProps>(({ pageName, theme }) => ({
  color: pageName ? 'rgba(255, 255, 255, 0.50)' : theme?.color?.typo.inverse,
  marginRight: '20px',
  textDecoration: 'unset',
}));
