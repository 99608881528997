import cn from 'classnames';
import { PropsWithChildren } from 'react';
import Flags from 'react-phone-number-input/flags';

import { getPhoneCountryIcon, StyledInternationalIcon } from './styled';
import { IPhoneCountryIconProps } from './types';

export const PhoneCountryIcon = getPhoneCountryIcon(
  ({
    country,
    className,
    ...rest
  }: PropsWithChildren<IPhoneCountryIconProps>) => {
    const CountryComp = Flags[country] || StyledInternationalIcon;

    if (!country) {
      return null;
    }

    return (
      <CountryComp
        className={cn('PhoneCountryIcon', className)}
        title={country}
        {...rest}
      />
    );
  },
);
