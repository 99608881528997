import { RefObject, useEffect, useRef, useState } from 'react';

import { PAGINATION_HEIGHT } from '../../constants';
import { ITableVirtualized } from '../../types';

const calculateHeightTable = (yDistance: number, outerHeight: number) =>
  outerHeight - yDistance - PAGINATION_HEIGHT;

export const useAdaptiveTableHeight = (
  isPinnedHeader: boolean | undefined,
  virtualized: ITableVirtualized,
) => {
  const refTableContent: RefObject<HTMLDivElement> = useRef();
  const [heightTable, setHeightTable] = useState(null);

  const currentElementContent = refTableContent?.current;
  const yDistance = Math.abs(currentElementContent?.getBoundingClientRect()?.y);

  useEffect(() => {
    if (isPinnedHeader && currentElementContent && virtualized) {
      setHeightTable({
        maxHeight: calculateHeightTable(yDistance, window.outerHeight),
      });
    } else {
      setHeightTable({
        maxHeight: 'unset',
      });
    }
  }, [currentElementContent]);

  return { refTableContent, heightTable };
};
