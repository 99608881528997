import { formatNumber, Skeleton } from 'react-ui-kit-exante';

import MarketValueStyles from './MarketValue.module.css';

interface IMarketValueProps {
  marketValue: number;
  isLoading: boolean;
  isFetching: boolean;
}

export const MarketValue = ({
  marketValue,
  isLoading = false,
  isFetching = false,
}: IMarketValueProps) => {
  return (
    <div className={MarketValueStyles.Wrapper}>
      <div className={MarketValueStyles.Value}>
        {isLoading || isFetching ? (
          <Skeleton variant="text" width={100} height={50} />
        ) : (
          formatNumber(marketValue)
        )}
      </div>
      <div className={MarketValueStyles.Label}>Market Value, EUR</div>
    </div>
  );
};
