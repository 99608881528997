import { createAsyncThunk } from '@reduxjs/toolkit';

import { workflowService } from '../../resources';
import type { TOption } from '../types';

export const fetchWorkflow = createAsyncThunk<
  TOption[],
  undefined,
  { rejectValue: string }
>('workflow/fetch', async (_, { rejectWithValue }) => {
  try {
    return await workflowService.fetchWorkflows({ active: true });
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
