import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { TExecution, TSelectedExecution } from '../../types';

import {
  clearSelectedExecutionAction,
  selectedExecutionAction,
} from './executions.actions';

const initialState: TSelectedExecution = { selectedExecution: null };

export const executionsSlice = createSlice({
  name: 'executions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      selectedExecutionAction,
      (state, action: PayloadAction<TExecution>) => ({
        ...state,
        selectedExecution: action.payload,
      }),
    );
    builder.addCase(clearSelectedExecutionAction, (state) => ({
      ...state,
      selectedExecution: null,
    }));
  },
});
