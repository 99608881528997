import { styled } from '@mui/material/styles';
import { TDefaultTheme } from 'react-ui-kit-exante';

type ContainerProps = {
  theme: TDefaultTheme;
};

export const Container = styled('div')(({ theme }: ContainerProps) => ({
  height: '24px',
  width: '24px',

  '& svg': {
    color: `${theme.color.icon.action} !important`,
  },
}));
