import { ThemeProvider } from '@mui/material/styles';
import cn from 'classnames';
import { useState } from 'react';

import * as Icons from '../../../Icons';
import { DEFAULT_APPLY_STATES } from '../../../Icons/constants';
import { useTheme } from '../../../theme';
import { Skeleton } from '../../Skeleton';

import { StyledIcon, StyledIconButton, StyledText } from './IconButton.styled';
import { getColor } from './helpers';
import { IconButtonProps } from './types';

export const IconButton = ({
  applyStates = DEFAULT_APPLY_STATES,
  children,
  className,
  disabled = false,
  iconColor = 'primary',
  iconName,
  iconSize = 24,
  isActive = false,
  label,
  showSkeleton = false,
  type = 'button',
  ...rest
}: IconButtonProps) => {
  const theme = useTheme();
  // eslint-disable-next-line import/namespace
  const IconComponent = Icons[iconName];
  const color = getColor(theme, iconColor, iconName);
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      {showSkeleton ? (
        <Skeleton
          className="IconButtonSkeleton"
          height={iconSize}
          width={label ? 100 : iconSize}
        />
      ) : (
        <StyledIconButton
          className={cn('IconButton', className)}
          type={type}
          {...rest}
          disabled={disabled}
          color={color}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onMouseDown={() => setActive(true)}
          onMouseUp={() => setActive(false)}
        >
          <StyledIcon
            className="IconButtonIconWrapper"
            iconSize={iconSize}
            iconName={iconName}
          >
            {IconComponent ? (
              <IconComponent
                applyStates={applyStates}
                size={iconSize}
                disabled={disabled}
                isActive={isActive || active}
                isHover={hover}
              />
            ) : (
              children
            )}
          </StyledIcon>
          {label && (
            <StyledText className="IconButtonLabel">{label}</StyledText>
          )}
        </StyledIconButton>
      )}
    </ThemeProvider>
  );
};
