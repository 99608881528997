export type TOption = {
  label: string;
  value: string;
  icon?: JSX.Element | string;
};

export enum EStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export type TStateField<T> = {
  status: EStatus;
  error: string | null;
  data: T;
};
