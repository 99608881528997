import { ListItemIcon as ListItemIconMUI } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Skeleton } from '../../Skeleton';

export const ListItemTextSkeleton = styled(Skeleton)`
  width: 100%;
  height: 24px;
`;

/** width, height are taken from {@link ListItemIcon} */
export const ListItemIconSkeleton = styled(ListItemIconMUI)`
  min-width: 0;
  width: 24px;
  height: 24px;
`;
