import cn from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { CodeEditor, Panel, Skeleton } from 'react-ui-kit-exante';

import { useGetWorkflowByHashSumQuery } from '../../store/workflowByHashSum';
import { IUseQueryReturn, type TWorkflow } from '../../types';

import WorkflowDetailsStyles from './WorkflowDetails.module.css';

export const WorkflowDetailsPage = () => {
  const { hashSum } = useParams<{ hashSum: string }>();

  const { data, isLoading, isFetching } =
    useGetWorkflowByHashSumQuery<IUseQueryReturn<TWorkflow>>(hashSum);
  const {
    json_field: jsonField = undefined,
    name,
    wf_type: wfType = undefined,
  } = data || {};

  const renderHead = () =>
    isLoading || isFetching ? (
      <Skeleton
        variant="rounded"
        width={350}
        height={40}
        sx={{ marginBottom: '8px' }}
      />
    ) : (
      name
    );

  return (
    <div>
      <Panel title={renderHead()} className={WorkflowDetailsStyles.Wrapper}>
        <Link
          className={cn(
            WorkflowDetailsStyles.Link,
            WorkflowDetailsStyles.Margin,
          )}
          to={`/wfe/history?workflow=${wfType}`}
        >
          History
        </Link>
        <Link
          className={WorkflowDetailsStyles.Link}
          to={`/wfe/executions?workflow=${wfType}`}
        >
          Executions
        </Link>
      </Panel>
      <CodeEditor
        disableContentPaddings
        height="650px"
        mode="json"
        readOnly
        theme="github"
        value={JSON.stringify(jsonField, null, '\t')}
      />
    </div>
  );
};
