import { UseTableCellProps } from 'react-table';
import { formatDate, IColumn } from 'react-ui-kit-exante';

import { WfType } from '../../constants';
import { IOption, TExecution } from '../../types';
import { getFormattedInfo } from '../../utils';

import { WORKFLOW_OPTION_RESET } from './constants';

export interface IGetColumnsProps {
  onFilter: (column: string, value: unknown) => void;
  onRemove: (column: string) => void;
  workflowOptions: IOption[] | [];
}

export const getColumns = ({
  onRemove,
  onFilter,
  workflowOptions,
}: IGetColumnsProps): IColumn<TExecution>[] => [
  {
    Header: 'Execution id',
    accessor: 'execution_id',
    width: 290,
    disableSortBy: true,
    onFilter,
    onRemove,
  },
  {
    Header: 'Workflow',
    accessor: 'workflow',
    required: true,
    type: 'select',
    onFilter,
    onRemove,
    filterOptions: [WORKFLOW_OPTION_RESET, ...workflowOptions],
  },
  {
    Header: 'Create time',
    accessor: 'create_time',
    formatting: 'dateUTC',
    width: 120,
    disableFilters: true,
    sortDescFirst: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            withTime: true,
          }),
      },
    },
  },
  {
    Header: 'Update time',
    accessor: 'update_time',
    type: 'dateRange',
    disableFilters: true,
    width: 120,
    formatting: 'dateUTC',
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            withTime: true,
          }),
      },
    },
  },
  {
    Header: 'Author',
    accessor: 'author',
    width: 160,
    onFilter,
    onRemove,
  },
  {
    Header: 'Approvers',
    accessor: 'approvers',
    disableSortBy: true,
    width: 200,
    onFilter,
    onRemove,
  },
  {
    Header: 'Info',
    accessor: 'info',
    disableFilters: true,
    disableSortBy: true,
    width: 400,
    Cell: ({ row }: UseTableCellProps<TExecution>) => {
      const { original } = row;
      const { info, wf_type: wfType } = original;

      const infoForTradeOrTransaction = getFormattedInfo(info, wfType);

      const allInfo = info
        ?.map((item: Record<string, unknown>) =>
          Object.entries(item).map(([key, value]) => `${key}: ${value}`),
        )
        ?.join(', ');

      let finalInfo = allInfo || '-';

      if (
        wfType === WfType.MANUAL_TRANSACTION ||
        wfType === WfType.MANUAL_TRADE ||
        wfType === WfType.FEE_SETUP ||
        wfType === WfType.FEE_SETUP_NEW_SETUPS ||
        wfType === WfType.FEE_SETUP_PIPELINE ||
        wfType === WfType.FEE_SETUP_GET ||
        wfType === WfType.FEE_SETUP_USER ||
        wfType === WfType.FEE_SETUP_GET_USER
      ) {
        finalInfo = infoForTradeOrTransaction;
      } else if (wfType === WfType.BULK_MANUAL_TRANSACTION) {
        finalInfo = 'BULK TRANSACTIONS';
      }

      return (
        <div style={{ whiteSpace: 'pre-wrap' }} title={finalInfo}>
          {finalInfo}
        </div>
      );
    },
  },
];
