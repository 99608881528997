import { alpha, styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../helpers';
import { Button } from '../../Button/Button';
import { Loader } from '../../Loader';

import { FILE_STATUS_TO_NOTIFICATION_COLOR } from './constants';
import type {
  TRootProps,
  TRowContainerProps,
  TUploadButtonProps,
} from './types';
import { TStateContainerProps, UploadStatus } from './types';

export const RootStyled = styled('div', {
  shouldForwardProp: blockNonNativeProps(['hasFile']),
})<TRootProps>(({ hasFile, theme }) => ({
  alignItems: 'flex-start',
  padding: hasFile ? '8px' : '0',
  borderStyle: hasFile ? 'dashed' : 'none',
  borderColor: hasFile
    ? theme?.color?.table?.bg?.selected
    : theme?.color?.dropdown?.list?.bg?.focus,
  borderWidth: '1px',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  [theme.breakpoints.up('sm')]: {
    borderStyle: 'dashed',
    padding: '8px',
  },
}));

export const Head = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

export const Body = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '8px',
}));

export const RowContainer = styled('div', {
  shouldForwardProp: blockNonNativeProps(['fullWidth']),
})<TRowContainerProps>(({ fullWidth }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '18px',
  justifyContent: 'space-between',
  width: fullWidth ? '100%' : 'auto',
}));

export const RowCustomHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme?.color?.typo?.primary,
  display: 'flex',
  fontSize: '20px',
  gap: '24px',
}));

export const RowContainerLeft = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme?.color?.button?.secondary?.typo?.default,
  display: 'flex',
  fontSize: theme?.size?.text?.md,
  gap: '24px',
}));

export const RowContainerRight = styled('div')(() => ({
  display: 'flex',
}));

export const UploadButton = styled(Button, {
  shouldForwardProp: blockNonNativeProps(['showUploadIconButtonOnly']),
})<TUploadButtonProps>(({ showUploadIconButtonOnly, theme }) => ({
  background: theme?.color?.button?.secondary?.gr?.hover,
  borderRadius: '4px',
  color: theme?.color?.typo?.secondary,
  fontSize: theme?.size?.text?.md,
  height: '32px',
  padding: '4px 8px',
  minWidth: 'auto',
  '& .MuiButton-startIcon': {
    marginRight: showUploadIconButtonOnly ? '0' : '4px',
    marginLeft: '0',
  },
  '&:hover': {
    background: theme?.color?.button?.secondary?.gr?.hover,
    color: theme?.color?.typo?.secondary,
  },
  '&::after': {
    height: 0,
    width: 0,
  },
}));

export const DragAndDropWrapper = styled('div')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    gap: '24px',
  },
}));

export const DragAndDropDownloadIcon = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '10px',
}));

export const StateContainer = styled('div', {
  shouldForwardProp: blockNonNativeProps(['status']),
})<TStateContainerProps>(({ theme, status }) => {
  const colorBg =
    theme?.color?.notifications?.[FILE_STATUS_TO_NOTIFICATION_COLOR?.[status]];
  const color =
    status === UploadStatus.SUCCESS ? theme?.color?.typo?.primary : colorBg;

  return {
    alignItems: 'center',
    backgroundColor: status ? alpha(colorBg, 0.1) : 'transparent',
    color: status ? color : theme?.color?.typo?.primary,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 10px',
    width: '100%',
  };
});

export const StateContainerLeft = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '10px',
  width: '100%',
}));

export const StateContainerRight = styled('div')(() => ({
  display: 'flex',
}));

export const StateFileName = styled('div')(({ theme }) => ({
  fontSize: theme?.size?.text?.md,
  fontFamily: theme?.font?.main,
  overflowWrap: 'anywhere',
  marginTop: '-2px',
}));

export const StateFileSize = styled('div')(({ theme }) => ({
  color: theme?.color?.typo?.ghost,
  fontSize: theme?.size?.text?.sm,
  fontFamily: theme?.font?.main,
  marginTop: '-2px',
}));

export const StateIcon = styled('div')(() => ({
  display: 'flex',
}));

export const LoaderStyled = styled(Loader)(({ theme }) => ({
  '.spinner-icon': {
    color: theme?.color?.notifications?.warning,
  },
}));

export const Text = styled('div')(({ theme }) => ({
  fontFamily: theme?.font?.main,
}));
