import { Notification } from 'react-ui-kit-exante';

import type { TParams } from 'types';
import { getErrorMessage } from 'utils';

import { workflowListMappers, workflowMappers } from './workflow.mappers';
import { WorkflowRepository } from './workflow.repository';

export class WorkflowService {
  public async fetchWorkflows(params: TParams) {
    try {
      const { data } = await WorkflowRepository.fetchWorkflowList(params);

      return workflowMappers(data);
    } catch (err) {
      Notification.error({
        title: getErrorMessage(err, 'While fetching workflows occurred error'),
      });

      return [];
    }
  }

  public async fetchWorkflowList(params: TParams) {
    try {
      const { data } = await WorkflowRepository.fetchWorkflowList(params);

      return workflowListMappers(data);
    } catch (err) {
      Notification.error({
        title: getErrorMessage(
          err,
          'While fetching workflow list occurred error',
        ),
      });

      return [];
    }
  }

  public async fetchWorkflowByHashSum(hashSum: string, params: TParams = {}) {
    try {
      const { data } = await WorkflowRepository.fetchWorkflowByHashSum(
        hashSum,
        params,
      );

      return data;
    } catch (err) {
      Notification.error({
        title: getErrorMessage(
          err,
          'While fetching workflow by hashSum occurred error',
        ),
      });

      return [];
    }
  }

  public async fetchWorkflowHistory(params: TParams) {
    try {
      const { data } = await WorkflowRepository.fetchWorkflowHistory(params);

      return data?.reverse();
    } catch (err) {
      Notification.error({
        title: getErrorMessage(
          err,
          'While fetching workflow history occurred error',
        ),
      });

      return [];
    }
  }
}
