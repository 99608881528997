import { Tooltip } from 'react-ui-kit-exante';

import { Statuses } from 'constants/index';
import { RestartIcon } from 'images/icons';

import { ErrorText, RestartWorkflow, Wrapper } from './styled';
import { ColorInfoBlockProps } from './types';

export const ColorInfoBlock = ({
  onRestartWorkflow,
  status,
  text,
  type,
}: ColorInfoBlockProps) => {
  const handleRestartWorkflow = () => {
    if (onRestartWorkflow) {
      onRestartWorkflow();
    }
  };

  return (
    <Wrapper>
      <ErrorText type={type}>{text}</ErrorText>
      {status === Statuses.FAILED && (
        <Tooltip title="Restart Workflow">
          <RestartWorkflow onClick={handleRestartWorkflow}>
            <RestartIcon />
          </RestartWorkflow>
        </Tooltip>
      )}
    </Wrapper>
  );
};
