import {
  TAccumulationMarketValue,
  TAccumulationMarketValuePayload,
} from '../../types';
import { apiRequest } from '../../utils';

import { ACCUMULATION_MARKET_VALUE_API } from './endpoints';

export class AccumMarketValueRepository {
  public static fetchMarketValue(payload: TAccumulationMarketValuePayload) {
    return apiRequest<TAccumulationMarketValue>({
      url: ACCUMULATION_MARKET_VALUE_API,
      method: 'POST',
      data: payload,
    });
  }
}
