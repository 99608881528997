import { configureStore } from '@reduxjs/toolkit';

import { accumMarketValueMiddlewares } from './accumMarketValue';
import { currencyMiddlewares } from './currency';
import { executionMiddlewares } from './execution';
import { rootReducer } from './rootReducer';
import { usersMiddlewares } from './users';
import { workflowByHashSumMiddlewares } from './workflowByHashSum';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware();

    const middlewares = [
      ...executionMiddlewares,
      ...accumMarketValueMiddlewares,
      ...workflowByHashSumMiddlewares,
      ...usersMiddlewares,
      ...currencyMiddlewares,
    ];

    if (process.env.NODE_ENV !== 'production') {
      return defaultMiddleware.concat([...middlewares]);
    }

    return defaultMiddleware.concat(middlewares);
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type TApplicationState = ReturnType<typeof store.getState>;

export type TDispatch = typeof store.dispatch;
