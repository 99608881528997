import { Panel } from 'react-ui-kit-exante';

import { IExecutionDetails } from '../../../types';

interface IFeeSetupProps {
  data: IExecutionDetails;
}

export const FeeSetupUser = ({ data }: IFeeSetupProps) => {
  const { info } = data;
  const [infoData] = info;
  const { second_side_doc: secondSideDoc } = infoData;
  const { title, url } = secondSideDoc || {};

  if (!title || !url) {
    return <Panel>No file to display</Panel>;
  }

  return <iframe src={`${url}#zoom=65%`} title={title} height="800px" />;
};
