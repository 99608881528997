import { Checkbox as CheckboxUI } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../helpers';

import { IControlsProps, IIconProps, IUICheckboxProps } from './types';

export const LabelStyled = styled(FormControlLabel, {
  shouldForwardProp: blockNonNativeProps(['checked']),
})<IUICheckboxProps>(({ checked, disabled, theme }) => ({
  margin: 0,
  opacity: disabled ? 0.4 : 1,
  '& .MuiFormControlLabel-label': {
    fontSize: '15px',
    fontFamily: theme.font.main,
    lineHeight: '16px',
    color: checked ? theme.color.typo.primary : theme.color.typo.promo,
    fontWeight: 400,
  },
}));

export const ControlStyled = styled(CheckboxUI)<IControlsProps>(() => ({
  padding: '0 8px 0 0',
}));

export const IconStyled = styled('span', {
  shouldForwardProp: blockNonNativeProps([
    'checked',
    'color',
    'theme',
    'warning',
  ]),
})<IIconProps>(({ checked, color, theme, warning }) => {
  const colorScheme = warning ? 'warning' : color;
  const checkedStatus = checked ? 'checked' : 'nonChecked';
  const styles = theme?.color.checkbox[colorScheme][checkedStatus];

  const borderWidth = checked ? 0 : '1px';

  return {
    borderRadius: '4px',
    width: '16px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    background: styles?.bg,
    borderStyle: 'solid',
    borderWidth,
    borderColor: styles?.border.default,
    boxShadow: styles?.boxShadow.default,

    '.MuiFormControlLabel-root:not(.Mui-disabled):hover &': {
      borderColor: styles?.border.hover,
      boxShadow: styles?.boxShadow.hover,
    },
    '.MuiFormControlLabel-root:not(.Mui-disabled) input:focus + &': {
      borderColor: styles?.border.focus,
      boxShadow: styles?.boxShadow.hover,
    },
    '& svg': {
      color: styles?.iconColor,
    },
  };
});
