import { FilterValue, TAdditionalFilter } from 'react-ui-kit-exante';

interface IGetAdditionalFiltersProps {
  filters: Record<string, unknown>;
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  defaultFilters: string[];
}

export function getAdditionalFilters({
  filters,
  onFilter,
  onRemove,
  defaultFilters,
}: IGetAdditionalFiltersProps): TAdditionalFilter[] {
  let [fromDate, toDate] = defaultFilters;

  if (filters.create_time) {
    const [createTimeFrom, createTimeTo] = filters.create_time as string[];
    const isCreateTimeFromYear1970 =
      new Date(createTimeFrom).getFullYear() === 1970;
    const isCreateTimeToYear1970 =
      new Date(createTimeTo).getFullYear() === 1970;

    if (isCreateTimeFromYear1970) {
      onFilter('create_time', [fromDate, toDate]);
    } else {
      fromDate = createTimeFrom;
    }

    if (isCreateTimeToYear1970) {
      onFilter('create_time', [fromDate, toDate]);
    } else {
      toDate = createTimeTo;
    }
  }

  return [
    {
      Header: 'Create time',
      accessor: 'create_time',
      type: 'dateRange',
      filterValue: [fromDate, toDate],
      onFilter,
      onRemove,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
    },
  ];
}
