import { useEffect } from 'react';

import { getColumnsWidths } from '../../helpers';

export function useSaveColumnsWidths(
  key: string,
  columnResizing: {
    columnWidths: { [key: string]: number };
    isResizingColumn: string | null;
  },
) {
  useEffect(() => {
    if (columnResizing.isResizingColumn === null) {
      const { columnWidths } = columnResizing;
      const savedWidths = getColumnsWidths(key) || {};
      const newSavedWidths = JSON.stringify({
        ...savedWidths,
        ...columnWidths,
      });
      localStorage.setItem(key, newSavedWidths);
    }
  }, [key, columnResizing]);
}
