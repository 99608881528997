import { format } from 'date-fns';
import { Filters } from 'react-table';

import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../../consts';
import { getBrowserLocale } from '../../../helpers';
import { getAvailableOptions } from '../helpers';
import {
  TAdditionalFilter,
  TExtendedColumnWithAdditionalFields,
} from '../types';

import { SEPARATOR } from './const';
import { IAdditionalFiltersData, IBaseFiltersData } from './innerTypes';
import { IPredefinedFilters } from './types';

// Using format from date-fns, because toLocaleDateString set 24:00 instead of 00:00
// it is important to creation new date
export const formatDate = (date: Date, withTime = false, useLocale = false) => {
  if (withTime) {
    return format(date, DATE_WITH_TIME_FORMAT);
  }
  if (useLocale) {
    return date.toLocaleDateString(getBrowserLocale());
  }
  return format(date, DATE_FORMAT);
};

interface IMakeFiltersDataResponse<Data extends object> {
  base: IBaseFiltersData<Data>;
  additional: IAdditionalFiltersData;
}

export function makeFiltersData<Data extends object>(
  allColumns: TExtendedColumnWithAdditionalFields<Data>[],
  data: Data[],
  additionalFilters: TAdditionalFilter[] = [],
): IMakeFiltersDataResponse<Data> {
  const availableFilters = allColumns.reduce((acc, current) => {
    if (current.canFilter) {
      return [
        ...acc,
        {
          ...current,
          filterOptions:
            current.filterOptions ||
            getAvailableOptions(data, current, SEPARATOR),
        },
      ];
    }

    return acc;
  }, []);
  const baseFiltersData = availableFilters.reduce<IBaseFiltersData<Data>>(
    (acc, curr) => ({
      entities: { ...acc.entities, [curr.id]: curr },
      options: [
        ...acc.options,
        { id: String(curr.Header), value: curr.id, type: 'base' },
      ],
    }),
    {
      entities: {},
      options: [],
    },
  );
  const additionalFiltersData =
    additionalFilters.reduce<IAdditionalFiltersData>(
      (acc, curr) => {
        const accessor = String(curr.accessor);
        const id = String(curr.Header);

        return {
          entities: {
            ...acc.entities,
            [accessor]: { ...curr, id: accessor },
          },
          options: [
            ...acc.options,
            {
              id,
              value: accessor,
              type: 'additional',
            },
          ],
        };
      },
      {
        entities: {},
        options: [],
      },
    );

  return { base: baseFiltersData, additional: additionalFiltersData };
}

interface IGetPredefinedFiltersArguments<Data extends object> {
  filters: Record<string, unknown>;
  base: IBaseFiltersData<Data>;
  additional: IAdditionalFiltersData;
}

export function getPredefinedFilters<Data extends object>({
  filters,
  base,
  additional,
}: IGetPredefinedFiltersArguments<Data>): IPredefinedFilters[] {
  return Object.keys(filters).reduce((acc, curr) => {
    const baseElement = base.entities[curr];
    const additionalElement = additional.entities[curr];
    const filterValue = filters[curr];

    if (baseElement) {
      return [
        ...acc,
        {
          id: baseElement.id,
          value: curr,
          type: 'base',
          filterValue,
        },
      ];
    }

    if (additionalElement) {
      return [
        ...acc,
        {
          id: additionalElement.id,
          value: curr,
          type: 'additional',
          filterValue,
        },
      ];
    }

    return acc;
  }, []);
}

export function getExistingFilters(
  filters: IPredefinedFilters[],
): Filters<Record<string, unknown>> {
  return filters
    .filter((i) => i.type !== 'additional')
    .map((item) => ({ id: item.value, value: item.filterValue }));
}
