import { UseTableCellProps } from 'react-table';
import { IColumn } from 'react-ui-kit-exante';

import { IOption, IWorkflowHistory } from '../../types';
import { WORKFLOW_OPTION_RESET } from '../ExecutionsPage/constants';

interface IGetColumnsProps {
  onFilter: (column: string, value: unknown) => void;
  onRemove: (column: string) => void;
  workflowOptions: IOption[] | [];
}

export const getColumns = ({
  onRemove,
  onFilter,
  workflowOptions,
}: IGetColumnsProps): IColumn<IWorkflowHistory>[] => [
  {
    Header: 'Name',
    accessor: 'workflow_name',
    width: 220,
    disableFilters: true,
  },
  {
    Header: 'Version',
    accessor: 'version',
    width: 80,
    disableFilters: true,
  },
  {
    Header: 'Type',
    accessor: 'workflow',
    width: 240,
    type: 'select',
    onFilter,
    onRemove,
    filterOptions: [WORKFLOW_OPTION_RESET, ...workflowOptions],
    Cell: ({ row: { original } }: UseTableCellProps<IWorkflowHistory>) =>
      original?.wf_type || null,
  },
  {
    Header: 'Valid',
    accessor: 'valid',
    width: 80,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Active',
    accessor: 'active',
    width: 80,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Hash',
    accessor: 'hash_sum',
    width: 490,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Errors',
    accessor: 'validation_errors',
    width: 80,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Author',
    accessor: 'author',
    width: 130,
    disableFilters: true,
  },
  {
    Header: 'Created',
    accessor: 'create_time',
    formatting: 'date',
    width: 120,
    disableFilters: true,
  },
];
