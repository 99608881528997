import type { TLogHandleTimeRequest } from 'types';
import { apiRequest } from 'utils';

import { FETCH_LOG_HANDLE_TIME } from './endpoints';

export class InfluxProxyRepository {
  public static async fetchLogHandleTime(data: TLogHandleTimeRequest) {
    return apiRequest({
      url: FETCH_LOG_HANDLE_TIME,
      method: 'POST',
      data,
    });
  }
}
