import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format, isValid } from 'date-fns';
import { merge } from 'lodash';
import { FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { CalendarIcon } from '../../../../../Icons';
import { useTheme } from '../../../../../theme';
import { INPUT_DATE_PICKER_DATA_TEST_ID } from '../../constants';
import { DatePicker } from '../DatePicker';
import { DEFAULT_MIN_DATE } from '../constants';
import { TDateCond } from '../types';

import { DatePickerInput } from './DatePickerInput';
import { StyledIconButton } from './InputDatePicker.styled';
import { getMaskFromFormat, isDateValid, selectDateFormat } from './helpers';
import { IInputDatePickerProps } from './types';

export const InputDatePicker = <WithRange extends boolean = false>({
  dateFormat,
  disabled,
  inputProps,
  locale,
  maxDate,
  minDate = DEFAULT_MIN_DATE,
  onCalendarClose,
  onCalendarOpen,
  onChange,
  onChangeRaw,
  onClear,
  onToday,
  placeholderText,
  selected,
  showSkeleton = false,
  showTimeInput,
  withSeconds,
  ...rest
}: IInputDatePickerProps<WithRange>) => {
  const theme = useTheme();
  const [valueRaw, setValueRaw] = useState(null);

  const [open, setOpen] = useState(false);
  const [valueToFormat, setValueToFormat] = useState<Date | null>(null);
  const [isRawValid, setIsRawValid] = useState(true);

  const selectedDateFormat =
    dateFormat || selectDateFormat(locale, showTimeInput, withSeconds);

  // Infer mask from format string
  const mask = useMemo(
    () => getMaskFromFormat(selectedDateFormat),
    [selectedDateFormat],
  );

  // Disable ripple effect for input button
  const newTheme = createTheme(
    merge({}, theme, {
      components: {
        MuiIconButton: { defaultProps: { disableRipple: true } },
      },
    }),
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
    onCalendarOpen?.();
  }, [onCalendarOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
    onCalendarClose?.();
  }, [onCalendarClose]);

  const handleChangeRaw = useCallback(
    (evt: FocusEvent<HTMLInputElement>) => {
      const value = evt.target.value;

      if (value != null) {
        setIsRawValid(
          isDateValid(
            value,
            selectedDateFormat,
            minDate || undefined,
            maxDate || undefined,
          ),
        );
      }

      setValueRaw(value);
      onChangeRaw?.(evt);
    },
    [selectedDateFormat, minDate, maxDate],
  );

  const handleToday = () => {
    setOpen(false);
    onToday?.();
  };

  const handleClear = () => {
    setOpen(false);
    onClear?.();
  };

  // Prepare input reformat on mask change
  useEffect(() => {
    if (selected == null) {
      return;
    }

    const isValidDate = isValid(selected);
    const defaultDate = new Date(0);

    const datePlaceholder = isValidDate ? selected : defaultDate;
    onChange(datePlaceholder as TDateCond<WithRange>, null);
    setValueToFormat(selected);
  }, [mask]);

  // Reformat input
  useEffect(() => {
    if (valueToFormat === null) {
      return;
    }

    onChange(valueToFormat as TDateCond<WithRange>, null);
    setValueToFormat(null);
  }, [valueToFormat]);

  // Reformat input on format change
  useEffect(() => {
    if (!selected) {
      return;
    }

    setValueRaw(format(selected, selectedDateFormat));
  }, [selected, selectedDateFormat]);

  return (
    <ThemeProvider theme={newTheme}>
      <DatePicker
        customInput={
          <DatePickerInput
            mask={mask}
            iconRight={
              <StyledIconButton
                onClick={handleOpen}
                size={inputProps?.size}
                disabled={disabled}
              >
                <CalendarIcon
                  dataTestId="InputDatePicker"
                  disabled={disabled}
                  size={24}
                />
              </StyledIconButton>
            }
            data-test-id={INPUT_DATE_PICKER_DATA_TEST_ID}
            error={!isRawValid || inputProps?.error}
            showSkeleton={showSkeleton}
            {...inputProps}
          />
        }
        dateFormat={selectedDateFormat}
        disabled={disabled}
        locale={locale}
        maxDate={maxDate}
        minDate={minDate}
        onCalendarClose={handleClose}
        onCalendarOpen={handleOpen}
        onChange={onChange}
        onChangeRaw={handleChangeRaw}
        onClear={handleClear}
        onToday={handleToday}
        open={open}
        placeholderText={placeholderText || selectedDateFormat?.toLowerCase()}
        selected={selected}
        value={valueRaw}
        showTimeInput={showTimeInput}
        {...rest}
      />
    </ThemeProvider>
  );
};
