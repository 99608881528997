import { Notification } from 'react-ui-kit-exante';

export const isEmptyField = (value: string | string[], fieldName: string) => {
  const fieldNameUppercase = fieldName.toUpperCase();

  if (Array.isArray(value) && !value?.length) {
    Notification.error({
      title: `The field "${fieldNameUppercase}" cannot be empty`,
    });

    return true;
  }

  if (typeof value === 'string' && !value?.trim()) {
    Notification.error({
      title: `The field "${fieldNameUppercase}" cannot be empty`,
    });

    return true;
  }

  return false;
};
