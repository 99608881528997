import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Table } from 'react-ui-kit-exante';

import { usePrevious } from 'hooks';
import { approvalGroupService } from 'resources';
import { IApprovalGroup } from 'types';

import { Routes } from '../../../constants';

import { getColumns } from './columns';
import {
  DISPLAYED_COLUMN_KEYS,
  FieldName,
  PAGE_SIZE,
  PAGE_SIZES,
} from './constants';
import { isEmptyField } from './helpers';

interface IGroupsProps {
  data: IApprovalGroup[];
  isLoading: boolean;
  logHandleTime: () => void;
  refetch: () => Promise<void>;
  tableId: string;
}

export const Groups = ({
  data,
  isLoading,
  logHandleTime,
  refetch,
  tableId,
}: IGroupsProps) => {
  const history = useHistory();

  const columns = useMemo(() => getColumns(), []);

  const handleAddApprovalGroup = useCallback(() => {
    history.push(Routes.ADD_GROUP);
  }, [history]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'AddApprovalGroup',
        component: (
          <IconButton
            iconColor="action"
            iconName="AddIcon"
            iconSize={24}
            label="Add Group"
            onClick={handleAddApprovalGroup}
          />
        ),
      },
    ],
    [handleAddApprovalGroup],
  );

  const handleSave = useCallback(
    async (_: IApprovalGroup, values: IApprovalGroup) => {
      if (
        isEmptyField(values, FieldName.NAME) ||
        isEmptyField(values, FieldName.DESCRIPTION)
      ) {
        await refetch();

        return;
      }

      await approvalGroupService.updateApprovalGroup(values);
      await refetch();
    },
    [refetch],
  );

  const handleDelete = useCallback(async (value: IApprovalGroup) => {
    if (value?.id) {
      await approvalGroupService.deleteApprovalGroup(value.id);
    }

    await refetch();
  }, []);

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: handleSave,
      additionalActions: [
        {
          label: (
            <IconButton
              iconColor="radical"
              iconName="DeleteIcon"
              iconSize={16}
            />
          ),
          title: 'Delete',
          onClick: handleDelete,
        },
      ],
    }),
    [handleSave, handleDelete],
  );

  const previousGroupData = usePrevious(data);

  useEffect(() => {
    if (data && !isEqual(previousGroupData, data)) {
      logHandleTime();
    }
  }, [data, logHandleTime, previousGroupData]);

  return (
    <Table
      additionalActions={additionalActions}
      columns={columns}
      data={data || []}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      pageSize={PAGE_SIZE}
      pageSizes={PAGE_SIZES}
      rowActions={rowActions}
      saveColumnOrder
      showTableInfo
      tableId={tableId}
      title="Approvals Group"
    />
  );
};
