import { useCallback, useMemo } from 'react';
import { OnSaveEditableRow, Table } from 'react-ui-kit-exante';

import { IExecutionDetailsSteps, TUpdateStepComment } from '../../../../types';
import { prepareTableId } from '../../../../utils';
import { DISPLAYED_COLUMN_KEYS } from '../constants';

import { getColumns } from './columns';

interface IStepsProps {
  executionId: string;
  steps: IExecutionDetailsSteps[];
  updateStepComment: (payload: TUpdateStepComment) => void;
}

export const Steps = ({
  executionId,
  steps,
  updateStepComment,
}: IStepsProps) => {
  const tableId = prepareTableId('steps');
  const columns = useMemo(() => getColumns(), []);

  const handleSave: OnSaveEditableRow<IExecutionDetailsSteps> = useCallback(
    async (_: IExecutionDetailsSteps, updated: IExecutionDetailsSteps) => {
      const payload: TUpdateStepComment = {
        step_id: updated?.step_id,
        comment: updated?.comment,
        execution_id: executionId,
      };

      return updateStepComment(payload);
    },
    [executionId, updateStepComment],
  );

  const rowActions = useMemo(
    () => ({
      onSave: handleSave,
      show: true,
    }),
    [handleSave],
  );

  return (
    <Table
      className="TextAlignment"
      columns={columns}
      data={steps || []}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      hasPagination
      isFlexLayout
      pageSize={100}
      pageSizes={[20, 50, 100]}
      rowActions={rowActions}
      showTableInfo
      tableId={tableId}
      title="Steps"
    />
  );
};
