import { combineReducers } from '@reduxjs/toolkit';

import { accumMarketValueQueries } from './accumMarketValue';
import { currencyQueries } from './currency';
import { executionQueries } from './execution';
import { executionsSlice } from './executions';
import { usersQueries } from './users';
import { workflowSlice } from './workflow';
import { workflowByHashSumQueries } from './workflowByHashSum';

export const rootReducer = combineReducers({
  [workflowSlice.name]: workflowSlice.reducer,
  [executionsSlice.name]: executionsSlice.reducer,
  ...executionQueries,
  ...accumMarketValueQueries,
  ...workflowByHashSumQueries,
  ...usersQueries,
  ...currencyQueries,
});
