import { ThemeProvider } from '@mui/material/styles';
import { FC } from 'react';

import { useTheme } from '../../theme';

import { FullscreenOverlayStyled } from './FullscreenOverlay.styled';
import type { TModal } from './FullscreenOverlay.types';

export const FullscreenOverlay: FC<TModal> = ({
  children,
  className,
  disableEscapeKeyDown = false,
  keepMounted = true,
  open = false,
  onClose,
  ...props
}) => {
  const theme = useTheme();

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <FullscreenOverlayStyled
        disableEscapeKeyDown={disableEscapeKeyDown}
        keepMounted={keepMounted}
        onClose={onCloseHandler}
        {...props}
        open={open}
      >
        <div className={className} data-test-id="fullscreen-overlay-div">
          {children}
        </div>
      </FullscreenOverlayStyled>
    </ThemeProvider>
  );
};
