import { setStartEndForDateRangeString } from 'utils';

import { IExecutionParams } from '../../types';

export function transformParams(params: IExecutionParams) {
  const paramsClone = { ...params };

  if (paramsClone.sorting) {
    delete paramsClone.sorting;
  }

  if (paramsClone.create_time) {
    const createTime = paramsClone.create_time;
    const createTimeFormatted = setStartEndForDateRangeString(createTime);

    paramsClone.create_time_gte = createTimeFormatted[0];
    paramsClone.create_time_lte = createTimeFormatted[1];

    delete paramsClone.create_time;
  }

  if (paramsClone.execution_id) {
    paramsClone.workflow_execution_id = paramsClone.execution_id;

    delete paramsClone.execution_id;
  }

  if (paramsClone.workflow) {
    paramsClone.wf_type = paramsClone.workflow;

    delete paramsClone.workflow;
  }

  return paramsClone;
}
