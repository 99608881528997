import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../helpers';

import { TRANSIENT_PROPS, TRANSIENT_TITLE_PROPS } from './constants';
import { getMaxHeight } from './helpers';
import { IRootProps, ITitleProps } from './types';

export const Root = styled('section', {
  shouldForwardProp: blockNonNativeProps(TRANSIENT_PROPS),
})<IRootProps>(
  ({ disableContentPaddings, disableBodyPaddings, maxHeight, theme }) => ({
    background: theme?.color.bg.primary,
    maxHeight: getMaxHeight(maxHeight),
    height: 'fit-content',
    padding: disableContentPaddings || disableBodyPaddings ? '24px 0' : '24px',
    overflowY: maxHeight ? 'auto' : 'unset',
  }),
) as React.ComponentType<IRootProps>;

export const Title = styled('div', {
  shouldForwardProp: blockNonNativeProps(TRANSIENT_TITLE_PROPS),
})<ITitleProps>(({ theme, disableBodyPaddings }) => ({
  padding: disableBodyPaddings ? '0 24px' : '0',
  margin: '0 0 24px',
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme?.breakpoints?.up('md') as string]: {
    marginBottom: '16px',
  },

  '.PanelHeading': {
    font: `400 24px/24px ${theme?.font.header}`,
    margin: '0',
    [theme?.breakpoints?.up('md') as string]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '32px',
      lineHeight: '48px',
    },
  },
})) as React.ComponentType<ITitleProps>;
