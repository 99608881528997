import { useCallback, useEffect, useState } from 'react';

import {
  FilterInputProps,
  TAdditionalFilter,
  TControlledFilters,
  TExtendedColumnWithAdditionalFields,
  TRemoveFilterHandler,
} from '../../types';
import {
  FilterEqualStyled,
  FilterLabelStyled,
  FilterRemoveStyled,
  FilterRowStyled,
} from '../Filters.styled';
import { FilterValue } from '../types';

import { FilterInput } from './FilterInput';
import { TFilterValueState } from './types';

export interface IFilterRowProps<Data extends object> {
  setFilter(id: string, value?: FilterValue): void;
  filter: TExtendedColumnWithAdditionalFields<Data> | TAdditionalFilter;
  onClose: (id: string) => void;
  filters?: TControlledFilters;
  filterInputProps?: FilterInputProps;
  useBrandedDatePicker?: boolean;
  requiredFilters?: string[];
  dataTestId?: string;
}

export const FilterRow = <Data extends Record<string, unknown>>({
  setFilter,
  filter,
  onClose,
  filters = {},
  filterInputProps,
  useBrandedDatePicker,
  requiredFilters = [],
  dataTestId,
}: IFilterRowProps<Data>) => {
  const [stateValue, setValue] = useState<TFilterValueState>(
    filter.filterValue || filters[filter.id],
  );

  const isNoRequiredFilter = !requiredFilters.includes(filter.id);

  const handleCloseFilter = useCallback(() => {
    setValue(undefined);

    if (filter.id) {
      setFilter(filter.id, undefined);
      onClose(filter.id);
    }

    if (filter.onRemove && filter.id) {
      (filter.onRemove as TRemoveFilterHandler)(filter.id, null);
    }
  }, [filter, onClose, setFilter]);

  useEffect(() => {
    if (filters[filter.id]) {
      setValue(filters[filter.id] as TFilterValueState);
    }
  }, [filters, filter]);

  return (
    <FilterRowStyled className="FilterRow" data-test-id={dataTestId}>
      <FilterLabelStyled className="FilterLabel">
        {filter.Header}
        <FilterEqualStyled className="FilterEqual">=</FilterEqualStyled>
      </FilterLabelStyled>

      <FilterInput
        filter={filter}
        stateValue={stateValue}
        setValue={setValue}
        setFilter={setFilter}
        filterInputProps={filterInputProps}
        useBrandedDatePicker={useBrandedDatePicker}
      />
      {isNoRequiredFilter && (
        <FilterRemoveStyled
          onClick={handleCloseFilter}
          data-test-id="filters__button--remove-filter"
          aria-label="Remove filter"
          iconSize={16}
          iconColor="secondary"
          iconName="CloseIcon"
        />
      )}
    </FilterRowStyled>
  );
};
