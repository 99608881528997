import {
  TUpdateStepComment,
  IExecutionData,
  IExecutionDetails,
  TParams,
  TApproveExecution,
  TDeclineExecution,
} from 'types';
import { apiRequest } from 'utils';

import {
  EXECUTION_API,
  EXECUTION_BULK_APPROVE_API,
  EXECUTION_BULK_DECLINE_API,
  getExecutionByIdAPI,
  getRestartExecutionByIdAPI,
  STEP_COMMENT_API,
} from './endpoints';

export class ExecutionRepository {
  public static fetchExecutions(params: TParams) {
    return apiRequest<IExecutionData>({
      params,
      url: EXECUTION_API,
    });
  }

  public static fetchExecution(id: string) {
    return apiRequest<IExecutionDetails>({
      url: getExecutionByIdAPI(id),
    });
  }

  public static updateStepComment(payload: TUpdateStepComment) {
    return apiRequest({
      url: STEP_COMMENT_API,
      method: 'PUT',
      data: payload,
    });
  }

  public static approveExecution(payload: TApproveExecution) {
    return apiRequest({
      url: EXECUTION_BULK_APPROVE_API,
      method: 'POST',
      data: payload,
    });
  }

  public static declineExecution(payload: TDeclineExecution) {
    return apiRequest({
      url: EXECUTION_BULK_DECLINE_API,
      method: 'POST',
      data: payload,
    });
  }

  public static restartExecution(id: string) {
    return apiRequest({
      url: getRestartExecutionByIdAPI(id),
      method: 'POST',
    });
  }
}
