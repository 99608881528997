import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Autocomplete,
  IconButton,
  Input,
  Panel,
  Select,
} from 'react-ui-kit-exante';

import { Routes } from '../../constants';
import { approvalSettingService } from '../../resources';
import { useGetCurrencyQuery } from '../../store/currency';
import {
  IApprovalSettingSubmitProps,
  IOption,
  IUseQueryReturn,
} from '../../types';

import AddApprovalSettingStyles from './AddApprovalSetting.module.css';
import { controlCls, defaultValues } from './constants';
import { validationSchema } from './validationSchema';

export const AddApprovalSettingPage = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { groupOptions, usersOptions } = state || {};
  const { data: currencyOptions, isLoading: isLoadingCurrency } =
    useGetCurrencyQuery<IUseQueryReturn<IOption[]>>(null);

  const handleClose = useCallback(() => {
    history.push(Routes.APPROVALS_GROUP);
  }, [history]);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
  } = methods;

  const onSubmit = async ({
    approvers,
    emails,
    ...rest
  }: IApprovalSettingSubmitProps) => {
    await approvalSettingService.createApprovalSetting({
      ...rest,
      approvers: approvers.map((item) => item?.value),
      emails: emails?.split(','),
      name: (Math.random() + 1).toString(36),
    });
    handleClose();
  };

  if (!groupOptions || !usersOptions) {
    history.push(Routes.APPROVALS_GROUP);

    return null;
  }

  const renderActions = () => (
    <div className={AddApprovalSettingStyles.Actions}>
      <IconButton
        disabled={!isDirty}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={24}
        label="Save"
        type="submit"
      />
      <IconButton
        iconColor="secondary"
        iconName="CloseIcon"
        iconSize={24}
        onClick={handleClose}
      />
    </div>
  );

  const controls = (
    <div className={AddApprovalSettingStyles.Controls}>
      <Controller
        name="approvalGroup"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            sx={controlCls}
            label="Approval Group"
            options={groupOptions}
            disabled={!groupOptions}
            warning={dirtyFields.approvalGroup}
            error={Boolean(errors.approvalGroup)}
            message={errors.approvalGroup?.message}
          />
        )}
      />
      <Controller
        name="approvalThreshold"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            type="number"
            sx={controlCls}
            label="Approval Threshold"
            warning={dirtyFields.approvalThreshold}
            error={Boolean(errors.approvalThreshold)}
            message={errors?.approvalThreshold?.message}
          />
        )}
      />
      <Controller
        name="currency"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            readOnly
            sx={controlCls}
            label="Currency"
            options={currencyOptions || []}
            disabled={isLoadingCurrency}
            warning={dirtyFields.currency}
            error={Boolean(errors.currency)}
            message={errors?.currency?.message}
          />
        )}
      />
      <Controller
        name="approvers"
        control={control}
        render={({ field }) => {
          const onChange = (
            event: React.ChangeEvent<EventTarget>,
            newValue: Record<string, any>,
          ) => field.onChange(newValue);

          const fieldValue = field.value;

          return (
            <Autocomplete
              {...field}
              isMultiple
              value={fieldValue}
              onChange={onChange}
              options={usersOptions}
              controlProps={{
                disabled: !usersOptions,
                placeholder: 'Approvers',
                error: Boolean(errors.approvers),
                message: errors?.approvers?.message,
                warning: dirtyFields.approvers as any,
              }}
              sx={controlCls}
            />
          );
        }}
      />
      <Controller
        name="emails"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            label="Emails"
            sx={controlCls}
            warning={dirtyFields.emails}
            error={Boolean(errors.emails)}
            message={errors?.emails?.message}
          />
        )}
      />
    </div>
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={AddApprovalSettingStyles.Wrapper}
    >
      <Panel title="Create Approval setting" action={renderActions()} />
      {controls}
    </form>
  );
};
