import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

import { StyledInput } from './DatePickerInput.styled';
import { IDatePickerInputProps } from './types';

const DatePickerInputMask = forwardRef<HTMLInputElement, any>((props, ref) => {
  const { onChange, ...rest } = props;

  return (
    <IMaskInput
      {...rest}
      inputRef={ref}
      onAccept={(value) =>
        onChange({
          target: { name: props.name, value },
          // this is needed to trick react-datepicker to properly update
          // the value, see `DatePicker.handleChange`
          isDefaultPrevented: () => false,
        })
      }
    />
  );
});
DatePickerInputMask.displayName = 'DatePickerInputMask';

export const DatePickerInput = forwardRef<
  HTMLDivElement,
  IDatePickerInputProps
>(({ mask, placeholder, label, ...rest }, ref) => {
  const isHiddenLabel = rest.size === 'small';

  return (
    <StyledInput
      {...rest}
      inputRef={ref}
      MaskedInputComponent={DatePickerInputMask}
      placeholder={label}
      inputProps={{ mask, placeholder, label: isHiddenLabel ? label : '' }}
    />
  );
});
DatePickerInput.displayName = 'DatePickerInput';
