import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Ul, LoaderStyled } from './AutocompleteAsyncStyled';
import { NullableUlElement, TListBoxProps } from './types';

export const ListBox = forwardRef(function ListBoxBase(
  props: TListBoxProps,
  ref: ForwardedRef<HTMLUListElement>,
) {
  const { children, onScroll, loading, ...rest } = props;

  const innerRef = useRef<HTMLUListElement>(null);

  useImperativeHandle<NullableUlElement, NullableUlElement>(
    ref,
    () => innerRef.current,
  );

  return (
    <Ul
      {...rest}
      onScroll={onScroll}
      loading={loading}
      ref={innerRef}
      role="listbox"
    >
      {loading ? <LoaderStyled size="l" /> : null}
      {children}
    </Ul>
  );
});
