import { formatDate, formatNumber } from 'react-ui-kit-exante';

import { IExecutionInfo, TInfoCombined } from '../../../../types';

interface ITransformValuesProps {
  data: IExecutionInfo | TInfoCombined;
  dateValues: string[];
  numberValues: string[];
}

export const transformValues = ({
  data,
  dateValues,
  numberValues,
}: ITransformValuesProps): TInfoCombined => {
  return Object.keys(data).reduce((prev, key) => {
    const value = data[key as keyof (IExecutionInfo | TInfoCombined)];

    if (numberValues.includes(key)) {
      return { ...prev, [key]: formatNumber(value) };
    }

    if (dateValues.includes(key) && typeof value === 'string') {
      return {
        ...prev,
        [key]: formatDate({
          date: value,
          withTime: true,
        }),
      };
    }

    return { ...prev, [key]: value };
  }, {} as TInfoCombined);
};
