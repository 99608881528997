import { format, isValid, parse } from 'date-fns';

import { isWithinIntervalSafe } from '../../DatePicker/helpers';

export const isDateValid = (
  date: string,
  fmt: string,
  start?: Date,
  end?: Date,
) => {
  if (!date) {
    return true;
  }
  const sampleDate = new Date();

  const parsed = parse(date, fmt, sampleDate);

  const startFormatted = start && parse(format(start, fmt), fmt, sampleDate);
  const endFormatted = end && parse(format(end, fmt), fmt, sampleDate);

  if (!isValid(parsed)) {
    return false;
  }

  return isWithinIntervalSafe({
    date: parsed,
    start: startFormatted,
    end: endFormatted,
  });
};
