import { isPlainObject } from 'lodash';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react';

import { useTranslator } from '../../../../contexts/TranslationContext';
import { IOption } from '../../../../types/form';
import { Autocomplete } from '../../../Inputs/Autocomplete';
import { IMultiSelectOption } from '../../../Inputs/Autocomplete/types';
import { AutocompleteAsync } from '../../../Inputs/AutocompleteAsync';
import { Checkbox } from '../../../Inputs/Checkbox';
import { Input } from '../../../Inputs/Input/Input';
import { Select } from '../../../Inputs/Select';
import {
  FilterInputProps,
  TAdditionalFilter,
  TAsyncMultiSelectFilterOption,
  TBooleanFilterHandler,
  TDateInputFilterHandler,
  TDateRangeFilterHandler,
  TDateRangeFilterOption,
  TDateTimeRangeFilterOption,
  TExtendedColumnWithAdditionalFields,
  TMultiSelectFilterOption,
  TNumberRangeFilterHandler,
  TStringArrayFilterHandler,
  TStringValueFilterHandler,
} from '../../types';
import { FilterInputStyled } from '../Filters.styled';
import {
  FilterType,
  FilterValue,
  TDateValue,
  TNumberRangeValue,
} from '../types';

import { DateInput } from './DateInput';
import { DateRangeInput } from './DateRangeInput';
import { NumberRangeInput } from './NumberRangeInput';
import {
  isValidFetchDataOption,
  preparedSelectOptions,
  prepareValueByFilterType,
} from './helpers';
import { TFilterValueState } from './types';

interface IFilterInputProps<Data extends object> {
  setFilter(id: string, value: FilterValue): void;
  filter: TExtendedColumnWithAdditionalFields<Data> | TAdditionalFilter;
  stateValue: TFilterValueState;
  setValue: Dispatch<SetStateAction<any>>;
  filterInputProps: FilterInputProps;
  useBrandedDatePicker?: boolean;
}

export const FilterInput = <Data extends object>({
  filter,
  stateValue,
  setValue,
  setFilter,
  filterInputProps,
  useBrandedDatePicker,
}: IFilterInputProps<Data>) => {
  const { t } = useTranslator();

  const filterId = filter.id;
  const onFilter = filter.onFilter;
  const fetchDataOption = (filter as TAsyncMultiSelectFilterOption)
    ?.fetchDataOption;
  const useLocale = (
    filter as TDateRangeFilterOption | TDateTimeRangeFilterOption
  ).useLocale;
  const onToday = (
    filter as TDateRangeFilterOption | TDateTimeRangeFilterOption
  ).onToday;
  const onYesterday = (
    filter as TDateRangeFilterOption | TDateTimeRangeFilterOption
  ).onYesterday;
  const onLastWeek = (
    filter as TDateRangeFilterOption | TDateTimeRangeFilterOption
  ).onLastWeek;

  const isDateRangeFilter = (
    val: unknown,
  ): val is TDateRangeFilterOption | TDateTimeRangeFilterOption => {
    return isPlainObject(val);
  };

  // Set inner react-table state for uncontrolled filters
  const setInnerTableFilter = useCallback(
    (value: FilterValue) => {
      if (filterId) {
        setFilter(filterId, value);
      }
    },
    [filterId, setFilter],
  );

  const onChangeDateRange = useCallback(
    (
      values: TDateValue[],
      onChange: (filterId: string, value: TDateValue[]) => void,
    ) => {
      setInnerTableFilter(values);
      setValue(values);

      if (filterId) {
        onChange(filterId, values);
      }
    },
    [filterId, setInnerTableFilter, setValue],
  );

  const handleMultiSelectChange = useCallback(
    (event: SyntheticEvent, values: (string | IMultiSelectOption)[]) => {
      const onlyValues = values.map((item) => {
        if (typeof item === 'string') {
          return item;
        }

        return item.value;
      });

      setValue(onlyValues);
      setInnerTableFilter(onlyValues);

      if (onFilter && filterId) {
        (onFilter as TStringArrayFilterHandler)(filterId, onlyValues);
      }
    },
    [filterId, onFilter, setInnerTableFilter, setValue],
  );

  const handleChange = useCallback(
    (value) => {
      setInnerTableFilter(value);
      setValue(value);

      if (onFilter && filterId) {
        (onFilter as TStringValueFilterHandler)(filterId, value);
      }
    },
    [filterId, onFilter, setInnerTableFilter, setValue],
  );

  const handleInputBlur = useCallback(
    ({ target: { value } }) => {
      handleChange(value);
    },
    [handleChange],
  );

  const handleAsyncSelectChange = useCallback(
    (event: SyntheticEvent, value: string | IOption) => {
      if (typeof value === 'string') {
        return handleChange(value);
      }
      return handleChange([value?.value, value?.label]);
    },
    [handleChange],
  );

  const handleTextInputChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setValue(value),
    [setValue],
  );
  const handleCheckboxChange = useCallback(
    (event: ChangeEvent, checked: boolean) => {
      setInnerTableFilter(checked);
      setValue(checked);

      if (onFilter && filterId) {
        (onFilter as TBooleanFilterHandler)(filterId, checked);
      }
    },
    [filterId, onFilter, setInnerTableFilter, setValue],
  );
  const handleNumberRangeChange = useCallback(
    (values: [TNumberRangeValue, TNumberRangeValue]) => {
      setInnerTableFilter(values);
      setValue(values);

      if (onFilter && filterId) {
        (onFilter as TNumberRangeFilterHandler)(filterId, values);
      }
    },
    [filterId, onFilter, setInnerTableFilter, setValue],
  );
  const handleDateInputChange = useCallback(
    (value: TDateValue) => {
      setInnerTableFilter(value);
      setValue(value);

      if (onFilter && filterId) {
        (onFilter as TDateInputFilterHandler)(filterId, value);
      }
    },
    [filterId, onFilter, setInnerTableFilter, setValue],
  );
  const handleDateRangeChange = useCallback(
    (values: TDateValue[]) => {
      setInnerTableFilter(values);
      setValue(values);

      if (onFilter && filterId) {
        (onFilter as TDateRangeFilterHandler)(filterId, values);
      }
    },
    [filterId, onFilter, setInnerTableFilter, setValue],
  );
  const handleTodayChange = useCallback(
    (values: TDateValue[]) => {
      setInnerTableFilter(values);
      setValue(values);

      if (onToday && filterId) {
        onToday(filterId, values);
      }
    },
    [filterId, onToday, setInnerTableFilter, setValue],
  );
  const handleYesterdayChange = useCallback(
    (values: TDateValue[]) => {
      setInnerTableFilter(values);
      setValue(values);

      if (onYesterday && filterId) {
        onYesterday(filterId, values);
      }
    },
    [filterId, onYesterday, setInnerTableFilter, setValue],
  );
  const handleLastWeekChange = useCallback(
    (values: TDateValue[]) => {
      setInnerTableFilter(values);
      setValue(values);

      if (onLastWeek && filterId) {
        onLastWeek(filterId, values);
      }
    },
    [filterId, onLastWeek, setInnerTableFilter, setValue],
  );

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && event.target) {
      event.target.blur();
    }
  }, []);

  const options = useMemo(() => filter.filterOptions || [], [filter]);
  const selectOptions = useMemo(
    () => preparedSelectOptions(options),
    [options],
  );

  const getPredefinedDateRangeFilters = useCallback(() => {
    if (isDateRangeFilter(filter) && filter?.predefinedDateRanges) {
      const { predefinedDateRanges } = filter;

      return {
        items: predefinedDateRanges,
        onChangeDateRange,
      };
    }
    return null;
  }, [onChangeDateRange, filter]);

  const inputs: Record<FilterType, JSX.Element> = {
    select: (
      <Select
        options={selectOptions}
        onChange={handleInputBlur}
        size="small"
        value={prepareValueByFilterType(stateValue, 'select')}
        fullWidth
      />
    ),
    multiSelect: (
      <Autocomplete
        dataTestId="filters__input--select"
        options={options as IMultiSelectOption[]}
        noOptionsText={t('ui__table__filters__multiselect__no_options')}
        onChange={handleMultiSelectChange}
        isMultiple
        freeSolo={(filter as TMultiSelectFilterOption)?.freeSolo}
        value={prepareValueByFilterType(stateValue, 'multiSelect')}
        isOptionEqualToValue={(option: IMultiSelectOption, value) =>
          // value will be string, because we set value as string[] in handler
          // 'as string' give error
          option.value === (value as unknown)
        }
        size="small"
        fullWidth
      />
    ),
    asyncMultiSelect: isValidFetchDataOption(fetchDataOption) ? (
      <AutocompleteAsync
        isMultiple
        options={[]}
        placeholder={filterId}
        fetchData={fetchDataOption}
        onChange={handleMultiSelectChange}
        value={prepareValueByFilterType(stateValue, 'multiSelect')}
        isOptionEqualToValue={(option: IMultiSelectOption, selectedValue) => {
          if (typeof selectedValue === 'string') {
            return selectedValue === option.value;
          }
          return selectedValue.value === option.value;
        }}
      />
    ) : null,
    asyncSelect: isValidFetchDataOption(fetchDataOption) ? (
      <AutocompleteAsync
        options={[]}
        placeholder={filterId}
        fetchData={fetchDataOption}
        onChange={handleAsyncSelectChange}
        value={prepareValueByFilterType(stateValue, 'asyncSelect')}
        getOptionLabel={(option: IOption | string) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.label || '';
        }}
        isOptionEqualToValue={(option: IMultiSelectOption, selectedValue) => {
          if (typeof selectedValue === 'string') {
            return selectedValue === option.value;
          }
          return selectedValue.value === option.value;
        }}
      />
    ) : null,
    textInput: (
      <Input
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        onChange={handleTextInputChange}
        key={filterId}
        value={prepareValueByFilterType(stateValue, 'textInput')}
        placeholder={t('ui__table__filters__textinput__placeholder')}
        size="small"
        fullWidth
        data-test-id="filters__input--text-input"
        inputProps={filterInputProps}
      />
    ),
    numberRange: (
      <NumberRangeInput
        onSubmit={handleNumberRangeChange}
        onChange={setValue}
        values={prepareValueByFilterType(stateValue, 'numberRange')}
        inputProps={filterInputProps}
      />
    ),
    dateInput: (
      <DateInput
        onChange={handleDateInputChange}
        value={prepareValueByFilterType(stateValue, 'dateInput')}
        useLocale={useLocale}
        useBrandedPicker={useBrandedDatePicker}
      />
    ),
    dateRange: (
      <DateRangeInput
        onChange={handleDateRangeChange}
        values={prepareValueByFilterType(stateValue, 'dateRange')}
        useLocale={useLocale}
        onToday={onToday ? handleTodayChange : undefined}
        onYesterday={onYesterday ? handleYesterdayChange : undefined}
        onLastWeek={onLastWeek ? handleLastWeekChange : undefined}
        predefinedDateRanges={getPredefinedDateRangeFilters()}
        useBrandedPicker={useBrandedDatePicker}
      />
    ),
    dateTimeRange: (
      <DateRangeInput
        useBrandedPicker={useBrandedDatePicker}
        onChange={(values) => handleDateRangeChange(values)}
        values={prepareValueByFilterType(stateValue, 'dateRange')}
        useLocale={useLocale}
        withTime
        onToday={onToday ? handleTodayChange : undefined}
        onYesterday={onYesterday ? handleYesterdayChange : undefined}
        onLastWeek={onLastWeek ? handleLastWeekChange : undefined}
        predefinedDateRanges={getPredefinedDateRangeFilters()}
      />
    ),
    checkbox: (
      <Checkbox
        checked={prepareValueByFilterType(stateValue, 'checkbox')}
        color="primary"
        label={t('ui__table__filters__checkbox__label')}
        name={filterId}
        onChange={handleCheckboxChange}
      />
    ),
  };

  const defaultInput = filter.type ? inputs[filter.type] : inputs.textInput;

  return <FilterInputStyled>{defaultInput}</FilterInputStyled>;
};
