import { Checkbox } from '../../../Inputs/Checkbox/Checkbox';
import { DefaultTableCellProps } from '../../TableBody/types';
import { FormattedTableCell } from '../FormattedTableCell';

/**
 * boolean: disabled Checkbox
 * null/undefined: –
 * string[]: string with ,
 */
export const DefaultTableCell = (props: DefaultTableCellProps) => {
  const {
    value,
    column: { formatting },
  } = props;
  if (formatting) {
    return <FormattedTableCell {...props} />;
  }
  switch (true) {
    case value === null || value === undefined || value === '':
      return '–';

    case typeof value === 'boolean':
      return <Checkbox checked={value} disabled title="" />;

    case Array.isArray(value) &&
      value.every((item) => typeof item === 'string'):
      return value.join(', ');

    case typeof value === 'object':
      return JSON.stringify(value);

    default:
      return value;
  }
};
