import { isObject } from 'lodash';
import { Cell } from 'react-table';

import { THorizontalAlign } from 'types';

import { formatters } from '../constants';

export const hasColumnStyleAndFormattingAttrs = <Data extends object>(
  column: unknown,
): column is Cell<Data>['column'] & {
  align: THorizontalAlign;
  className: string;
  formatting: keyof typeof formatters;
} =>
  isObject(column) &&
  ('align' in column || 'className' in column || 'formatting' in column);
