import { styled } from '@mui/material/styles';

import { ReactComponent as GlobeIcon } from '../../../../../assets/globe.svg';

export const getPhoneCountryIcon = (component) =>
  styled(component)(() => ({
    height: '16px',
    width: '16px',
    transform: 'scale(1.2)',
  }));

export const StyledInternationalIcon = styled(GlobeIcon)(({ theme }) => ({
  color: theme.color.icon.secondary,
}));
