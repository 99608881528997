import InputAdornment from '@mui/material/InputAdornment';
import { ThemeProvider } from '@mui/material/styles';
import cn from 'classnames';
import { forwardRef, useState } from 'react';

import { useTheme } from '../../../theme';
import { ForwardRefFC } from '../../../types';
import { Skeleton } from '../../Skeleton';

import { EyeButton } from './EyeButton';
import { IconWrapper, Root, TextField } from './Input.styled';
import { IUIInputProps } from './types';

export const Input: ForwardRefFC<IUIInputProps> = forwardRef(
  (
    {
      placeholder,
      message,
      size = 'medium',
      iconLeft,
      iconRight,
      disabled,
      fullWidth,
      readOnly = false,
      MaskedInputComponent,
      showSkeleton = false,
      type,
      className,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme();
    const isHiddenLabel = size === 'small';

    const [innerType, setType] = useState(type);

    const isPasswordType = innerType === 'password' && type === 'password';

    const setPasswordType = () => {
      if (isPasswordType) {
        setType('text');
      } else {
        setType('password');
      }
    };

    const EyeBtn = (
      <EyeButton isPasswordType={isPasswordType} onClick={setPasswordType} />
    );

    const rightIcon = type === 'password' ? EyeBtn : iconRight;

    return (
      <ThemeProvider theme={theme}>
        <Root fullWidth={!!fullWidth} className="InputWrapper">
          {showSkeleton ? (
            <Skeleton
              height={size === 'small' ? 32 : 48}
              width={fullWidth ? '100%' : 200}
              className={cn('InputSkeleton', className)}
            />
          ) : (
            <>
              <TextField
                inputRef={ref}
                disabled={disabled}
                fullWidth={fullWidth}
                helperText={disabled ? '' : message}
                size={size}
                hasLeftIcon={Boolean(iconLeft)}
                hiddenLabel={isHiddenLabel}
                label={isHiddenLabel ? '' : placeholder}
                placeholder={isHiddenLabel ? placeholder : ''}
                type={innerType}
                InputProps={{
                  ...(MaskedInputComponent
                    ? { inputComponent: MaskedInputComponent }
                    : {}),
                  endAdornment: rightIcon ? (
                    <InputAdornment position="end">{rightIcon}</InputAdornment>
                  ) : null,
                  readOnly,
                }}
                className={cn('InputComponent', className)}
                {...rest}
              />
              {iconLeft && (
                <IconWrapper hasHelperText={Boolean(message)}>
                  {iconLeft}
                </IconWrapper>
              )}
            </>
          )}
        </Root>
      </ThemeProvider>
    );
  },
);

Input.displayName = 'Input';
