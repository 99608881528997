import type { TParams } from '../../types';
import { apiRequest } from '../../utils';

import {
  getWorkflowByHashSum,
  WORKFLOW_API,
  WORKFLOW_HISTORY_API,
} from './endpoints';

export class WorkflowRepository {
  public static fetchWorkflowList(params: TParams) {
    return apiRequest({
      params,
      url: WORKFLOW_API,
    });
  }

  public static fetchWorkflowByHashSum(hashSum: string, params: TParams) {
    return apiRequest({
      params,
      url: getWorkflowByHashSum(hashSum),
    });
  }

  public static fetchWorkflowHistory(params: TParams) {
    return apiRequest({
      params,
      url: WORKFLOW_HISTORY_API,
    });
  }
}
