import { Breadcrumbs as BreadcrumbsUI, Link as MUILink } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ArrowRightIcon } from '../../Icons';
import { blockNonNativeProps } from '../../helpers';
import { Select } from '../Inputs/Select';

import { IStyledBreadcrumbsProps } from './types';

export const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

export const StyledBreadcrumbs = styled(BreadcrumbsUI, {
  shouldForwardProp: blockNonNativeProps(['bookmarks']),
})<IStyledBreadcrumbsProps>(({ theme, bookmarks }) => ({
  height: bookmarks && bookmarks.length ? 'auto' : '16px',
  color: theme?.color.typo.ghost,
  fontSize: '13px',
  lineHeight: '16px',
  fontFamily: theme?.font.main,
  fontWeight: '400',
}));

export const StyledSpan = styled('span')(({ theme }) => ({
  color: theme?.color.typo.ghost,
}));

export const StyledLink = styled(MUILink)(() => ({
  color: 'inherit',
  '&:hover': {
    textDecoration: 'none',
    color: 'inherit',
  },
})) as typeof MUILink;

export const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    color: theme?.color.typo.ghost,
    '&:hover, &:focus, &.Mui-focused': {
      color: theme?.color.typo.ghost,
      backgroundColor: 'transparent',
    },
  },
  '& .MuiInputBase-root': {
    borderColor: theme?.color.dropdown.border,
    borderWidth: '1px',
    backgroundColor: 'transparent',
    '&:hover, &.Mui-focused': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    display: 'block',
  },
}));

export const StyledSeparator = styled(ArrowRightIcon)(() => ({
  margin: '0 2px',
}));
