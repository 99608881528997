import { createTheme, Theme } from '@mui/material/styles';

import { TBaseTheme } from './TBaseTheme';
import { defaultThemeValues } from './defaultThemeValues';

export const defaultTheme = createTheme(defaultThemeValues);

export type TDefaultThemeValue = typeof defaultThemeValues;

export type TDefaultTheme = Theme;

declare module '@mui/material/styles/createTheme' {
  interface Theme extends TBaseTheme, TDefaultThemeValue {}
}
