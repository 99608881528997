import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Panel, Skeleton, Table } from 'react-ui-kit-exante';

import { WfType } from '../../../../constants';
import { prepareTableId } from '../../../../utils';
import Styles from '../ExecutionContainer.module.css';
import { getColumns } from '../Steps/columns';
import { DISPLAYED_COLUMN_KEYS } from '../constants';

import SkeletonStyles from './ExecutionSkeleton.module.css';

export const ExecutionSkeleton = () => {
  const { state } = useLocation();
  const { locationInfo, locationWfType } = state || {};
  const tableId = prepareTableId('executionDetailsSkeleton');
  const columns = useMemo(() => getColumns(), []);
  const tableClassName = 'TextAlignment';
  const isFeeSetupUser =
    locationWfType === WfType.FEE_SETUP ||
    locationWfType === WfType.FEE_SETUP_USER;

  return (
    <>
      <Panel
        title={<Skeleton variant="text" width={350} height={50} />}
        action={<Skeleton variant="rounded" width={250} height={53} />}
      />
      <div className={Styles.Wrapper}>
        {locationInfo && (
          <Panel className={Styles.CardStyle}>
            <h2 className={SkeletonStyles.Title}>Info</h2>
            <div key="key1" className={SkeletonStyles.CardRow}>
              <Skeleton variant="text" width={100} height={20} />
              <Skeleton variant="text" width={50} height={20} />
            </div>
            <div key="key2" className={SkeletonStyles.CardRow}>
              <Skeleton variant="text" width={120} height={20} />
              <Skeleton variant="text" width={90} height={20} />
            </div>
            <div key="key3" className={SkeletonStyles.CardRow}>
              <Skeleton variant="text" width={90} height={20} />
              <Skeleton variant="text" width={70} height={20} />
            </div>
            <div key="key4" className={SkeletonStyles.CardRow}>
              <Skeleton variant="text" width={90} height={20} />
              <Skeleton variant="text" width={60} height={20} />
            </div>
            <div key="key5" className={SkeletonStyles.CardRow}>
              <Skeleton variant="text" width={80} height={20} />
              <Skeleton variant="text" width={60} height={20} />
            </div>
            <div key="key6" className={SkeletonStyles.CardRow}>
              <Skeleton variant="text" width={90} height={20} />
              <Skeleton variant="text" width={100} height={20} />
            </div>
            <div key="key7" className={SkeletonStyles.CardRow}>
              <Skeleton variant="text" width={150} height={20} />
              <Skeleton variant="text" width={50} height={20} />
            </div>
            <div key="key8" className={SkeletonStyles.CardRow}>
              <Skeleton variant="text" width={130} height={20} />
              <Skeleton variant="text" width={30} height={20} />
            </div>
            <div key="key9" className={SkeletonStyles.CardRow}>
              <Skeleton variant="text" width={110} height={20} />
              <Skeleton variant="text" width={60} height={20} />
            </div>
            <div key="key10" className={SkeletonStyles.CardRow}>
              <Skeleton variant="text" width={170} height={20} />
              <Skeleton variant="text" width={60} height={20} />
            </div>
          </Panel>
        )}
        <div className={Styles.TableStyle}>
          {isFeeSetupUser ? (
            <div className={SkeletonStyles.FeeSetup}>
              <Skeleton variant="rounded" height={800} />
            </div>
          ) : (
            <Table
              isLoading
              data={[]}
              title="Steps"
              isFlexLayout
              showTableInfo
              hasPagination
              pageSize={100}
              columns={columns}
              tableId={tableId}
              className={tableClassName}
              pageSizes={[20, 50, 100]}
              displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
            />
          )}
        </div>
      </div>
    </>
  );
};
