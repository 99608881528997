import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, useTableData } from 'react-ui-kit-exante';

import { RefreshButton } from '../../components/RefreshButton';
import { DEFAULT_POOLING_INTERVAL_DATA_20, EMPTY_ARRAY } from '../../constants';
import { useInterval, useLogHandleTime, usePrevious } from '../../hooks';
import { executionService } from '../../resources';
import { selectWorkflow } from '../../store/workflow';
import { IExecutionData } from '../../types';
import {
  calculateCountOfPages,
  getThreeDaysDefaultFilter,
  prepareTableId,
} from '../../utils';

import { MarketValue } from './MarketValue';
import { getColumns } from './columns';
import { DISPLAYED_COLUMN_KEYS, PAGE_SIZE, PAGE_SIZES } from './constants';
import { getAdditionalFilters } from './filters';
import { getUsername } from './helpers';
import { useBulkActions } from './hooks';

export const ApprovalsPage = () => {
  const history = useHistory();
  const [onActionLoading, setOnActionLoading] = useState(false);
  const [isRefreshBtnClicked, setIsRefreshBtnClicked] = useState(false);
  const initialLoad = useRef(true);
  const { data: workflowOptions } = useSelector(selectWorkflow);
  const tableId = prepareTableId('approvals');

  const { logHandleTime, setStartHandleTime } =
    useLogHandleTime('approvals-page');

  const getApprovals = useCallback(
    ({ params: { approvers, skip, ...rest } }) => {
      setStartHandleTime();
      let username = '';

      if (window.runUIhistoryContainer) {
        username = getUsername();
      }

      if (initialLoad.current && username.includes('@')) {
        initialLoad.current = false;

        return executionService.fetchExecutions({
          ...rest,
          approver: username,
          offset: skip,
          status: 'suspended',
        });
      }

      return executionService.fetchExecutions({
        ...rest,
        approver: approvers,
        offset: skip,
        status: 'suspended',
      });
    },
    [setStartHandleTime],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getApprovals },
      saveViewParamsAfterLeave: true,
      tableId,
    }),
    [getApprovals, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    page,
    setPage,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData: refetch,
  } = useTableData<IExecutionData>(tableDataArgs);

  const {
    marketValue,
    handleSelectRow,
    onApprove,
    onDecline,
    selectedRows,
    setSelectedRows,
  } = useBulkActions({ refetch, setOnActionLoading });

  useInterval(() => {
    setSelectedRows([]);
    refetch();
  }, DEFAULT_POOLING_INTERVAL_DATA_20);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        workflowOptions,
      }),
    [removeFilter, setFilter, selectedRows, workflowOptions],
  );
  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        filters,
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getThreeDaysDefaultFilter(),
      }),
    [filters, removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [additionalFilters, resetFilters, filters],
  );
  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const getRowProps = () => ({
    style: {
      cursor: 'pointer',
    },
  });

  const handleCellClick = useCallback(
    ({ row: { original }, value }) => {
      if (value) {
        history.push(`/wfe/approvals/${original.execution_id}`, {
          locationInfo: !!original.info?.length,
          locationWfType: original.wf_type,
          locationWorkflow: original.workflow,
        });
      }
    },
    [history],
  );

  const previousApprovals = usePrevious(data?.results);

  const handleRefreshClick = useCallback(() => {
    setSelectedRows([]);
    setIsRefreshBtnClicked(true);
    refetch();
  }, [refetch]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'RefreshPage',
        component: (
          <RefreshButton loading={isLoading} onRefresh={handleRefreshClick} />
        ),
      },
    ],
    [handleRefreshClick, isLoading],
  );

  useEffect(() => {
    if (data?.results && !isEqual(previousApprovals, data.results)) {
      logHandleTime();
    }
  }, [data, logHandleTime, previousApprovals]);

  useEffect(() => {
    if (isRefreshBtnClicked && !isLoading) {
      setIsRefreshBtnClicked(false);
    }
  }, [isLoading, isRefreshBtnClicked]);

  const isInitialLoading = !data && isLoading;

  return (
    <Table
      additionalActions={additionalActions}
      bulkActions={{
        onApprove,
        onDecline,
        selectedRows,
        approveText: 'Approve',
        declineText: 'Decline',
        setSelectedRows: handleSelectRow,
      }}
      columns={columns}
      commonValue={{ label: 'Selected Market Value', value: marketValue }}
      customHeadComponent={<MarketValue />}
      data={data?.results || EMPTY_ARRAY}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      filteringProps={filteringProps}
      getRowProps={getRowProps}
      handleCellClick={handleCellClick}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isInitialLoading || onActionLoading || isRefreshBtnClicked}
      isPinnedHeader
      pageSize={PAGE_SIZE}
      pageSizes={PAGE_SIZES}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showTableInfo
      showScrollbar
      tableId={tableId}
      title="Approvals"
    />
  );
};
