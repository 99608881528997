import { isValid } from 'date-fns';
import { isArray } from 'lodash';
import qs from 'qs';

import { IFiltersProps } from '../types';

export function getUrlWithNewQueryParameter(key: string, value: string) {
  let url = window.location.href;

  const regExp = new RegExp(`([?&])${key}=.*?(&|#|$)(.*)`, 'gi');

  if (regExp.test(url)) {
    return url.replace(regExp, `$1${key}=${value}$2$3`);
  }

  const separator = url.indexOf('?') !== -1 ? '&' : '?';
  const hash = url.split('#');
  url = `${hash[0] + separator + key}=${value}`;

  if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
    url += `#${hash[1]}`;
  }

  return url;
}

export function addQueryParameter(key: string, value: string) {
  const encodedValue = encodeURIComponent(value);
  const preparedUrl = getUrlWithNewQueryParameter(key, encodedValue);

  window.history.pushState(null, '', preparedUrl);
}

export const removeURLParameter = (parameter: string) => {
  const url = window.location.href;

  const preparedUrl = url
    .replace(new RegExp(`[?&]${parameter}=[^&#]*(#.*)?$`), '$1')
    .replace(new RegExp(`([?&])${parameter}=[^&]*&`), '$1');

  window.history.replaceState(null, '', preparedUrl);
};

export function stringifyQueryValue(
  value: Date[] | Date | string | string[] | number | boolean | void | unknown,
) {
  if (value instanceof Date && isValid(value)) {
    return value.toISOString();
  }
  if (isArray(value)) {
    return String(
      value.map((v) =>
        v instanceof Date && isValid(v) ? v.toISOString() : String(v),
      ),
    );
  }

  return String(value);
}

export function setQueryParameter(
  key: string,
  value: Date[] | Date | string | string[] | number | boolean | void | unknown,
) {
  if (value === undefined || value === 'null' || value === '') {
    removeURLParameter(key);

    return;
  }

  const preparedValue = stringifyQueryValue(value);

  addQueryParameter(key, preparedValue);
}

export function getParameterValueByQueryName(
  name: string,
  url = window.location.href,
) {
  const query = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${query}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const getArrFromParam = (value: string) => {
  const split = value.split(',');
  return split.length > 1 ? split : value;
};

export function getParamsFromUrl() {
  const queryParams = qs.parse(window.location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  });

  return Object.keys(queryParams).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: getArrFromParam(getParameterValueByQueryName(curr)),
    }),
    {},
  );
}

export function resetQueryParamsWithDefaults(filtersList: string[]) {
  const [url] = window.location.href.split('?');
  const params = new URLSearchParams(window.location.search);

  filtersList.forEach((item) => {
    params.delete(item);
  });

  window.history.pushState(null, '', `${url}?${params}`);
}

interface IInjectedFilterProps {
  filtersProps?: IFiltersProps;
  defaultFilters: Record<string, unknown>;
  paramsFromUrl: Record<string, string>;
  savedFilters?: Record<string, unknown>;
}

export function getInjectedFilterProps({
  filtersProps,
  paramsFromUrl,
  defaultFilters,
  savedFilters,
}: IInjectedFilterProps) {
  return {
    ...filtersProps,
    initialState: { ...defaultFilters, ...savedFilters, ...paramsFromUrl },
  };
}
