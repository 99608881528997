import { useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useSpring } from 'react-spring';

import { TDefaultTheme } from '../../../theme';

import { SpinnerIconSvg } from './SpinnerIcon.styled';

export const SpinnerIcon = () => {
  const animationStyle = useSpring({
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
    loop: true,
    config: {
      friction: 0,
      duration: 1000,
    },
  });
  const theme: TDefaultTheme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <SpinnerIconSvg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={animationStyle}
        className="spinner-icon"
      >
        <path
          d="M8 2V4"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.9"
          d="M12.2426 3.75739L10.8284 5.1716"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.8"
          d="M14 8H12"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.7"
          d="M12.2426 12.2426L10.8284 10.8284"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.6"
          d="M8 14V12"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.5"
          d="M3.75732 12.2426L5.17154 10.8284"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.4"
          d="M2 8H4"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.3"
          d="M3.75732 3.75739L5.17154 5.1716"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SpinnerIconSvg>
    </ThemeProvider>
  );
};
