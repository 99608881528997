import { ThemeProvider } from '@mui/material/styles';
import { forwardRef } from 'react';

import SaveIcon from '../../../Icons/components/Save';
import { useTheme } from '../../../theme';
import { ForwardRefFC } from '../../../types';

import { LabelStyled, ControlStyled, IconStyled } from './Checkbox.styled';
import { IUICheckboxProps } from './types';

export const Checkbox: ForwardRefFC<IUICheckboxProps, HTMLInputElement> =
  forwardRef(
    (
      {
        checked,
        color = 'primary',
        controlProps,
        disabled,
        label = '',
        onChange,
        warning = false,
        ...props
      },
      ref,
    ) => {
      const theme = useTheme();

      return (
        <ThemeProvider theme={theme}>
          <LabelStyled
            checked={checked}
            control={
              <ControlStyled
                checked={checked}
                checkedIcon={
                  <IconStyled
                    checked={!!checked}
                    color={color}
                    warning={warning}
                  >
                    <SaveIcon />
                  </IconStyled>
                }
                disabled={disabled}
                icon={
                  <IconStyled
                    checked={!!checked}
                    color={color}
                    warning={warning}
                  />
                }
                inputRef={ref}
                onChange={onChange}
                disableRipple
                {...controlProps}
              />
            }
            disabled={disabled}
            label={label}
            {...props}
          />
        </ThemeProvider>
      );
    },
  );

Checkbox.displayName = 'Checkbox';
