import cn from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';
import { Panel } from 'react-ui-kit-exante';

import { DateFormats, KEYS } from '../../constants';

import CardStyles from './Card.module.css';
import { isValueType } from './helpers';
import { ICardNewProps } from './types';

export const Card: FC<ICardNewProps> = ({ title, data, className }) => {
  return Object.keys(data).length ? (
    <Panel className={cn(CardStyles.Wrapper, className)}>
      <h2 className={CardStyles.Title}>{title}</h2>
      {Object.entries(data).map(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          return (
            <div key={key} className={CardStyles.CardRow}>
              <div className={CardStyles.Key}>{key}</div>
              <div className={CardStyles.Value}>{value.join(', ')}</div>
            </div>
          );
        }

        if (KEYS.includes(key)) {
          return (
            <div key={key} className={CardStyles.CardRow}>
              <div className={CardStyles.Key}>{key}</div>
              <div className={CardStyles.Value}>
                {dayjs(value).format(DateFormats.DDMMYYYYHHMM)}
              </div>
            </div>
          );
        }

        return (
          isValueType(value) &&
          value.toString() && (
            <div key={key} className={CardStyles.CardRow}>
              <div className={CardStyles.Key}>{key}</div>
              <div className={CardStyles.Value}>{value.toString()}</div>
            </div>
          )
        );
      })}
    </Panel>
  ) : null;
};
