import { useMemo } from 'react';
import { Panel, Table } from 'react-ui-kit-exante';

import { MarketValue } from 'components/MarketValue';

import { ActionsApproveDecline } from '../../../components/ActionsApproveDecline';
import { InfoNotifications } from '../../../components/InfoNotifications';
import { useGetAccumMarketValueQuery } from '../../../store/accumMarketValue';
import {
  IExecutionDetails,
  IUseQueryReturn,
  TAccumulationMarketValue,
} from '../../../types';
import { prepareTableId } from '../../../utils';

import { getColumns } from './columns';
import { DISPLAYED_COLUMN_KEYS } from './constants';

interface IBulkTransactionsProps {
  data: IExecutionDetails;
  onApproveExecution: (value: string) => void;
  onDeclineExecution: (value: string) => void;
  onRestartWorkflow: () => void;
}

export const BulkTransactions = ({
  data,
  onApproveExecution,
  onDeclineExecution,
  onRestartWorkflow,
}: IBulkTransactionsProps) => {
  const tableId = prepareTableId('bulk-transactions');
  const { info, workflow } = data;
  const columns = useMemo(() => getColumns(), []);

  const {
    data: marketValueData,
    isLoading,
    isFetching,
  } = useGetAccumMarketValueQuery<IUseQueryReturn<TAccumulationMarketValue>>(
    {
      workflow_execution_ids: [data.execution_id],
    },
    {
      skip: !data.execution_id,
    },
  );

  return (
    <>
      <Panel
        title={workflow}
        action={
          <>
            <InfoNotifications
              data={data}
              onRestartWorkflow={onRestartWorkflow}
            />
            <ActionsApproveDecline
              onDeclineExecution={onDeclineExecution}
              onApproveExecution={onApproveExecution}
              data={data}
            />
          </>
        }
      />
      <Table
        columns={columns}
        customHeadComponent={
          <MarketValue
            marketValue={marketValueData?.market_value || 0}
            isLoading={isLoading}
            isFetching={isFetching}
          />
        }
        data={info || []}
        displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
        hasPagination
        isFlexLayout
        isPinnedHeader
        pageSize={100}
        pageSizes={[20, 50, 100]}
        saveColumnOrder
        saveViewParamsAfterLeave
        showTableInfo
        tableId={tableId}
      />
    </>
  );
};
