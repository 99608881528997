import { Notification } from 'react-ui-kit-exante';

import { EMPTY_OBJECT } from '../../constants';
import { TAccumulationMarketValuePayload } from '../../types';
import { getErrorMessage } from '../../utils';

import { AccumMarketValueRepository } from './accumMarketValue.repository';

export class AccumMarketValueService {
  public async fetchMarketValue(payload: TAccumulationMarketValuePayload) {
    try {
      const { data } = await AccumMarketValueRepository.fetchMarketValue(
        payload,
      );

      return data;
    } catch (err) {
      Notification.error({
        title: getErrorMessage(
          err,
          'While fetching accumulation market value occurred error',
        ),
      });

      return EMPTY_OBJECT;
    }
  }
}
