import { DATE_FORMATS } from '../../Components/Inputs/Date/constants';
import { DEFAULT_FILTER_LOCALE } from '../../Components/Table/TableFilters/const';
import { DATE_FORMAT } from '../../consts';
import { getBrowserLocale } from '../getBrowserLocale';

export const getBrowserDateFormat = (withTime = false, useLocale = false) => {
  const inputFormat = useLocale
    ? DATE_FORMATS[getBrowserLocale().toLowerCase()] ||
      DATE_FORMATS[DEFAULT_FILTER_LOCALE]
    : DATE_FORMAT;

  if (withTime) {
    return `${inputFormat} HH:mm`;
  }

  return inputFormat;
};
