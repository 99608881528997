import { UseTableCellProps } from 'react-table';
import { IColumn } from 'react-ui-kit-exante';

import { IExecutionInfo } from 'types/executionDetails';

export const getColumns = (): IColumn<IExecutionInfo>[] => [
  {
    Header: 'Asset ID',
    accessor: 'asset',
    disableSortBy: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    formatting: 'number',
  },
  {
    Header: 'Price',
    accessor: 'price',
    formatting: 'number',
    Cell: ({ row: { original } }: UseTableCellProps<IExecutionInfo>) =>
      original?.price || '-',
  },
  {
    Header: 'Account ID',
    accessor: 'accountId',
  },
  {
    Header: 'Operation type',
    accessor: 'operationType',
    minWidth: 200,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    disableSortBy: true,
    minWidth: 200,
  },
  {
    Header: 'Internal comment',
    accessor: 'internalComment',
    disableSortBy: true,
    minWidth: 175,
  },
  {
    Header: 'Use auto cash conversion',
    accessor: 'useAutoCashConversion',
    minWidth: 240,
  },
];
