export const EXECUTION_API = '/api/v2/workflow_execution/';
export const EXECUTION_BULK_APPROVE_API =
  '/api/v2/workflow_execution/bulk-approve';
export const EXECUTION_BULK_DECLINE_API =
  '/api/v2/workflow_execution/bulk-decline';
export const STEP_COMMENT_API = '/api/v2/update-step-comment/';

export const getExecutionByIdAPI = (id: string) => `${EXECUTION_API}${id}`;

export const getRestartExecutionByIdAPI = (id: string) =>
  `${EXECUTION_API}${id}/restart-execution`;
