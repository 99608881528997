import { FC } from 'react';

import { Loader } from '../Loader';

import { Props } from './CenteredLoader.types';

/**
 * Centered version of {@link Loader}
 *
 * Use `<Loader isCentered />` instead of this as it's deprecated.
 *
 * @deprecated since 4.28.1
 */
export const CenteredLoader: FC<Props> = ({ isInner = false, size = 'l' }) => {
  return <Loader isCentered isInner={isInner} size={size} />;
};
