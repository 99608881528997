import { createAction } from '@reduxjs/toolkit';

import type { TExecution } from '../../types';

export const selectedExecutionAction = createAction<TExecution>(
  'executionDetails/selected',
);
export const clearSelectedExecutionAction = createAction(
  'executionDetails/clear-selected',
);
