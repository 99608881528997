import { CellValue } from 'react-table';

export const prepareSelectedRow = (bulkActions, rows) => {
  const selectedRows = bulkActions?.selectedRows;

  if (bulkActions && rows) {
    return rows.reduce((acc: CellValue, curr) => {
      const isChecked = !!selectedRows
        .map((item) => item?.original?.id)
        .includes(curr?.original?.id);

      return {
        ...acc,
        [curr.id]: bulkActions.isSelectAll ? !isChecked : isChecked,
      };
    }, {});
  }

  return {};
};
