import { TInfoCombined } from '../../../../types';

interface ITransformKeysProps {
  transformedData: TInfoCombined;
  schedule?: string;
}

export function transformKeys<
  T extends TInfoCombined,
  K extends keyof TInfoCombined,
>(data: T, includedKeys: string[]): ITransformKeysProps {
  let schedule;

  const transformedData = Object.keys(data)
    .filter((key) => {
      if ('schedule' in data) {
        schedule = data.schedule;

        return false;
      }

      return includedKeys.includes(key);
    })
    .reduce((prev, key) => {
      let transformedKey;

      if (key.includes('_')) {
        /**
         * key = cross_rate
         * transformedKey = cross rate
         */
        transformedKey = key.replace('_', ' ');
      } else {
        /**
         * key = settlementBrokerAccountId
         * transformedKey = settlement broker accountId
         */
        transformedKey = key
          .split(/(?=[A-Z])/g)
          .join(' ')
          .toLowerCase();
      }

      return {
        ...prev,
        [transformedKey ?? key]: data[key as K],
      };
    }, {} as T);

  return {
    transformedData,
    schedule,
  };
}
