import { IColumn } from '../../types';

export const prepareEditableRowValues = <Data extends Record<string, any>>(
  editableRowValues: Data,
  columns: IColumn<Data>[],
) => {
  const preparedValues: Record<string, any> = {};
  Object.keys(editableRowValues).forEach((key) => {
    const column = columns.find(({ id }) => id === key);
    if (
      column?.editParams &&
      column.editParams.editable &&
      column.editParams.inputType === 'multiSelect'
    ) {
      let multiSelectValues = editableRowValues[key];
      if (!Array.isArray(multiSelectValues)) {
        if (typeof multiSelectValues === 'string') {
          multiSelectValues = multiSelectValues.split(',');
        } else {
          multiSelectValues = [];
        }
      }
      preparedValues[key] = multiSelectValues.map((option: any) => {
        if (typeof option === 'object' && 'value' in option) {
          return option.value;
        }

        return option.trim();
      });

      return;
    }
    preparedValues[key] = editableRowValues[key];
  });

  return preparedValues as Data;
};
