import { cloneDeep } from 'lodash';

import { IExecutionDetails, IExecutionDetailsSteps } from 'types';

export const groupByStepGroup = (steps: IExecutionDetailsSteps[]) => {
  const stepsList = cloneDeep(steps);

  /**
   * Iterate steps grouping by stepGroup
   */
  return stepsList
    .reverse()
    .reduce<IExecutionDetailsSteps[]>((result, step, index) => {
      const stepClone = cloneDeep(step);
      const prevStep = result[index - 1];

      if (!stepClone.stepGroup) {
        result.push({
          ...stepClone,
          groupLastStep: true,
        });

        return result;
      }

      if (stepClone.stepGroup === prevStep?.stepGroup) {
        /**
         * If group has more than 1 step
         * Then we set previous step's groupLastStep to false
         * Only last step of stepGroup will be true
         */
        prevStep.groupLastStep = false;

        /**
         * If we don't have status on current step,
         * then we will inherit status from previous step
         */
        if (!stepClone.status) {
          stepClone.status = prevStep.status;
        }

        /**
         * We inherit create_time from first step of stepGroup
         */
        stepClone.create_time = prevStep.create_time;
      }

      result.push({
        ...stepClone,
        groupLastStep: true,
      });

      return result;
    }, [])
    .filter((step) => step.groupLastStep);
};

export function executionMappers(execution: IExecutionDetails) {
  const { current_state: currentState, info, steps } = execution;
  const [mainInfo] = info;
  const [mainCurrentState] = currentState;

  return {
    ...execution,
    infoCombined: {
      ...mainInfo,
      ...mainCurrentState,
    },
    steps: groupByStepGroup(steps),
  };
}
