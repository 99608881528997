import { forwardRef, useEffect, useRef, useCallback } from 'react';

import { Checkbox } from '../../../Inputs/Checkbox';
import { IndeterminateInputProps } from '../../types';

export const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IndeterminateInputProps
>(({ indeterminate, bulkActions, row, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  const handleOnClick = useCallback(
    (e) => {
      if (bulkActions?.setSelectedRows) {
        bulkActions.setSelectedRows(row);
      }
      row?.toggleRowSelected();
      e.stopPropagation();
    },
    [bulkActions, row],
  );

  useEffect(() => {
    const selectedRows = bulkActions?.selectedRows;

    if (selectedRows?.some((selectedRow) => selectedRow === row?.id)) {
      row?.toggleRowSelected();
    }
  }, [row, bulkActions]);

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox {...rest} ref={resolvedRef} onClick={handleOnClick} />;
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

export const IndeterminateCheckboxHead = forwardRef<
  HTMLInputElement,
  IndeterminateInputProps
>(({ indeterminate, bulkActions, header, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;
  const isAllRowsSelected = header?.getToggleAllRowsSelectedProps().checked;

  const handleOnClick = useCallback(
    (e) => {
      if (isAllRowsSelected) {
        if (bulkActions?.setIsSelectAll) {
          bulkActions.setIsSelectAll(false);
        }
        bulkActions.setSelectedRows([]);
      } else if (bulkActions.setIsSelectAll) {
        bulkActions.setIsSelectAll(true);
        bulkActions.setSelectedRows([]);
      } else {
        bulkActions.setSelectedRows(header.columns[0].filteredRows);
      }

      header.toggleAllRowsSelected();
      e.stopPropagation();
    },
    [bulkActions, isAllRowsSelected, header],
  );

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Checkbox
      {...rest}
      ref={resolvedRef}
      onClick={handleOnClick}
      checked={isAllRowsSelected || false}
    />
  );
});

IndeterminateCheckboxHead.displayName = 'IndeterminateCheckboxHead';
