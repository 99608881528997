import { array, number, object, string } from 'yup';

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const validationSchema = object().shape({
  approvalGroup: string().required('Approval Group is a required field'),
  approvalThreshold: number()
    .typeError('Approval Threshold must be a number')
    .required('Approval Threshold is a required field'),
  currency: string().required('Currency is a required field'),
  approvers: array().min(1).required('Approvers is a required field'),
  emails: string()
    .required('Emails is a required field')
    .test('check email', 'Invalid email', (str) => {
      const arr = str?.split(',');
      let res = true;

      arr?.forEach((item: string) => {
        res = !!validateEmail(item.trim());
      });

      return res;
    }),
});
