import { formatDate, formatNumber } from 'react-ui-kit-exante';

import { WfType } from '../constants';

export const getFormattedInfo = (info: Record<string, any>, wfType: string) => {
  let formattedInfo: string[];

  const infoObject = info?.[0] || {};
  const {
    price,
    asset,
    amount,
    comment,
    quantity,
    currency,
    symbolId,
    accountId,
    valueDate,
    internalComment,
    name,
    feed_type: feedType,
    value,
    management_fee: managementFee,
    client,
    asset_manager: assetManager,
    fee_setup: feeSetup,
    setup_owner: setupOwner,
    account_id: accountIdNew,
  } = infoObject;

  const assetStr = asset ? `asset: ${asset}` : '';
  const amountStr =
    typeof amount === 'number' ? `amount: ${formatNumber(amount)}` : '';
  const commentStr = comment ? `comment: ${comment}` : '';
  const currencyStr = currency ? `currency: ${currency}` : '';
  const symbolIdStr = symbolId ? `symbolId: ${symbolId}` : '';
  const accountIdStr = accountId ? `accountId: ${accountId}` : '';
  const quantityStr = quantity ? `quantity: ${formatNumber(quantity)}` : '';
  const priceStr =
    typeof price === 'number' ? `price: ${formatNumber(price)}` : '';
  const valueDateStr = valueDate
    ? `value date: ${formatDate({ date: valueDate })}`
    : '';
  const internalCommentStr = internalComment
    ? `internal comment: ${internalComment}`
    : '';
  const nameStr = name ? `name: ${name}` : '';
  const feedTypeStr = feedType ? `feed type: ${feedType}` : '';
  const valueStr =
    typeof value === 'number' ? `value: ${formatNumber(value)}` : '';
  const managementFeeStr =
    typeof managementFee === 'number'
      ? `management fee: ${formatNumber(managementFee)}`
      : '';
  const clientStr = client ? `client: ${client}` : '';
  const assetManagerStr = assetManager ? `asset manager: ${assetManager}` : '';
  const feeSetupStr = feeSetup ? `fee setup: ${feeSetup}` : '';
  const setupOwnerStr = setupOwner ? `setup owner: ${setupOwner}` : '';
  const accountIdNewStr = accountIdNew ? `accountId: ${accountIdNew}` : '';

  if (wfType === WfType.MANUAL_TRADE) {
    formattedInfo = [
      accountIdStr,
      symbolIdStr,
      currencyStr,
      quantityStr,
      priceStr,
      valueDateStr,
      commentStr,
      internalCommentStr,
    ];
  } else if (
    wfType === WfType.FEE_SETUP ||
    wfType === WfType.FEE_SETUP_NEW_SETUPS ||
    wfType === WfType.FEE_SETUP_PIPELINE
  ) {
    formattedInfo = [
      accountIdNewStr,
      nameStr,
      feedTypeStr,
      valueStr,
      managementFeeStr,
      setupOwnerStr,
    ];
  } else if (
    wfType === WfType.FEE_SETUP_GET ||
    wfType === WfType.FEE_SETUP_GET_USER
  ) {
    formattedInfo = [];
  } else if (wfType === WfType.FEE_SETUP_USER) {
    formattedInfo = [clientStr, assetManagerStr, feeSetupStr];
  } else {
    formattedInfo = [
      symbolIdStr,
      accountIdStr,
      assetStr,
      amountStr,
      priceStr,
      commentStr,
      internalCommentStr,
    ];
  }

  return formattedInfo.filter((item) => !!item).join(', ');
};
