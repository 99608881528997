import { IMask } from 'react-imask';

export const BLOCK_RANGE_HOURS = {
  mask: IMask.MaskedRange,
  placeholderChar: 'HH',
  from: 0,
  to: 23,
  maxLength: 2,
};

export const BLOCK_RANGE_MINUTES = {
  mask: IMask.MaskedRange,
  placeholderChar: 'MM',
  from: 0,
  to: 59,
  maxLength: 2,
};

export const BLOCK_RANGE_SECONDS = {
  SS: {
    mask: IMask.MaskedRange,
    placeholderChar: 'SS',
    from: 0,
    to: 59,
    maxLength: 2,
  },
};
