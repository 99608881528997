export enum WfType {
  MANUAL_TRADE = 'manual_trade',
  MANUAL_CHARGE_FEE = 'manual_charge_fee',
  MANUAL_TRANSACTION = 'manual_transaction',
  BULK_MANUAL_TRANSACTION = 'bulk_manual_transaction',
  FEE_SETUP = 'feeSetupApproval',
  FEE_SETUP_PIPELINE = 'fee_setup_approval_pipeline',
  FEE_SETUP_NEW_SETUPS = 'fee_setup_get_new_setups',
  FEE_SETUP_GET = 'getFeeSetup',
  FEE_SETUP_USER = 'feeSetupUserApproval',
  FEE_SETUP_GET_USER = 'getFeeSetupUser',
}

export enum Statuses {
  FAILED = 'failed',
  SUCCEED = 'succeed',
  SUSPENDED = 'suspended',
}

export enum CommonStatuses {
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum Routes {
  APPROVALS = '/wfe/approvals',
  EXECUTIONS = '/wfe/executions',
  APPROVALS_DETAILS = '/wfe/approvals/:executionId',
  EXECUTIONS_DETAILS = '/wfe/executions/:executionId',
  WORKFLOWS = '/wfe/list',
  WORKFLOW_DETAILS = '/wfe/list/:hashSum',
  HISTORY = '/wfe/history',
  APPROVALS_GROUP = '/wfe/approvals-group',
  ADD_GROUP = '/wfe/approvals-group/add-group',
  ADD_SETTINGS = '/wfe/approvals-group/add-settings',
}

export const statusesOptions = [
  { value: '', label: 'Reset' },
  { value: 'suspended', label: 'Suspended' },
  {
    value: 'running',
    label: 'Running',
  },
  {
    value: 'succeed',
    label: 'Succeed',
  },
  {
    value: 'scheduled',
    label: 'Scheduled',
  },
  {
    value: 'failed',
    label: 'Failed',
  },
];

export const KEYS = ['current_time', 'timestamp'];
export const TYPES = ['string', 'number', 'boolean'];

export const REGEX = /[{}'"()]/g;

export * from './common';
export * from './session';
export * from './dateFormats';
