import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table, useTableData } from 'react-ui-kit-exante';

import { useQuery } from '../../hooks';
import { workflowService } from '../../resources';
import { selectWorkflow } from '../../store/workflow';
import { IWorkflowHistory } from '../../types';
import { prepareTableId } from '../../utils';

import { getColumns } from './columns';
import { DISPLAYED_COLUMN_KEYS, PAGE_SIZE, PAGE_SIZES } from './constants';

export const HistoryPage = () => {
  const tableId = prepareTableId('history');
  const { data: workflowOptions } = useSelector(selectWorkflow);
  const query = useQuery();

  const getHistory = useCallback(
    ({ filtersParams }) => workflowService.fetchWorkflowHistory(filtersParams),
    [],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getHistory },
      tableId,
    }),
    [getHistory, tableId],
  );

  const { data, isLoading, setFilter, removeFilter, resetFilters, filters } =
    useTableData<IWorkflowHistory[]>(tableDataArgs);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        workflowOptions,
      }),
    [setFilter, removeFilter, workflowOptions],
  );

  const filteringProps = useMemo(
    () => ({
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [resetFilters, filters],
  );

  useEffect(() => {
    if (query.get('workflow')) {
      setFilter('workflow', query.get('workflow'));
    }
  }, []);

  return (
    <Table
      columns={columns}
      data={data || []}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      filteringProps={filteringProps}
      hasPagination
      hasFilters
      isFlexLayout
      isLoading={isLoading}
      isPinnedHeader
      pageSize={PAGE_SIZE}
      pageSizes={PAGE_SIZES}
      saveColumnOrder
      showTableInfo
      tableId={tableId}
      title="History"
    />
  );
};
