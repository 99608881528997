import { FilterValue } from 'react-ui-kit-exante';

const START_RANGE = new Date(2011, 0, 1);

export const getPredefinedDateRanges = (
  onFilter: (col: string, value: FilterValue) => void,
) => [
  {
    title: 'all time',
    onFilter,
    range: [START_RANGE, new Date()],
  },
];
