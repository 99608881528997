import { styled } from '@mui/material/styles';
import { IconButton, blockNonNativeProps } from 'react-ui-kit-exante';

import { CommonStatuses } from 'constants/index';

import { ErrorTextProps, RestartWorkflowProps } from './types';

export const Wrapper = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
}));

export const ErrorText = styled('div', {
  shouldForwardProp: blockNonNativeProps(['type']),
})(({ theme, type }: ErrorTextProps) => ({
  background:
    type === CommonStatuses.SUCCESS
      ? theme?.color?.table?.bg?.selected
      : theme?.color?.button?.radical?.typo?.focus,
  borderRadius: '4px',
  color:
    type === CommonStatuses.SUCCESS
      ? theme?.color?.typo?.action
      : theme?.color?.typo?.radical,
  fontSize: '15px',
  margin: 0,
  maxWidth: '560px',
  padding: '20px',
  width: 'fit-content',
}));

export const RestartWorkflow = styled(IconButton)(
  ({ theme }: RestartWorkflowProps) => ({
    color: theme?.color?.icon?.action,

    '& svg': {
      height: '28px',
      width: '28px',
    },

    '&:hover': {
      color: theme?.color?.icon?.promo,
    },
  }),
);
