import { useCallback, useEffect, useMemo } from 'react';

import { TABLE_FIRST_PAGE } from '../../TablePagination/const';
import {
  IFiltersProps,
  IPaginationProps,
  ISortingProps,
  TCommonParams,
} from '../types';
import { useFilters } from '../useFilters';
import { usePagination } from '../usePagination';
import { useSorting } from '../useSorting';

import {
  getInjectedFilterProps,
  getParamsFromUrl,
  removeURLParameter,
  resetQueryParamsWithDefaults,
  setQueryParameter,
} from './heplers';

interface IUseQueryParamsProps {
  filters?: IFiltersProps;
  sorting?: ISortingProps;
  pagination?: IPaginationProps;
}

// it needed to avoid rerender
const DEFAULT_EMPTY_PROPS = {};

export function useQueryParams({
  filters: filtersProps = DEFAULT_EMPTY_PROPS,
  sorting: sortingProps = DEFAULT_EMPTY_PROPS,
  pagination: paginationProps = DEFAULT_EMPTY_PROPS,
}: IUseQueryParamsProps) {
  const paramsFromUrl = useMemo(() => getParamsFromUrl(), []);
  const defaultFilters = useMemo(
    () =>
      filtersProps.getDefaultFilters ? filtersProps.getDefaultFilters() : {},
    [filtersProps],
  );
  const injectedFilterProps = useMemo(
    () =>
      getInjectedFilterProps({
        filtersProps,
        defaultFilters,
        paramsFromUrl,
        savedFilters: filtersProps?.savedFilters || {},
      }),
    [defaultFilters, filtersProps, paramsFromUrl],
  );

  const { filters, setFilter, resetFilters, removeFilter } =
    useFilters(injectedFilterProps);
  const { page, setLimit, limit, setPage, skip, setSkip } =
    usePagination(paginationProps);
  const { sorting, setSorting } = useSorting(sortingProps);

  useEffect(() => {
    Object.keys(filters).forEach((param) =>
      setQueryParameter(param, filters[param]),
    );

    // Sets query params into url when component initialize
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const params: TCommonParams = useMemo(() => {
    const prepareFiltersForParams = filtersProps?.prepareFiltersForParams;
    const prepareSortingForParams = sortingProps?.prepareSortingForParams;

    const preparedFilters = prepareFiltersForParams
      ? prepareFiltersForParams(filters)
      : filters;
    const preparedSorting = prepareSortingForParams
      ? prepareSortingForParams(sorting)
      : sorting;

    return {
      ...preparedFilters,
      sorting: preparedSorting,
      limit,
      page,
      skip,
    };
  }, [filtersProps, sortingProps, filters, limit, skip, sorting, page]);

  const handleFilterChange = useCallback(
    (column: string, value: unknown) => {
      setFilter(column, value);
      setPage(TABLE_FIRST_PAGE);
      setQueryParameter(column, value);
    },
    [setFilter, setPage],
  );
  const handleRemoveFilter = useCallback(
    (column: string) => {
      removeFilter(column);
      setPage(TABLE_FIRST_PAGE);
      removeURLParameter(column);
    },
    [removeFilter, setPage],
  );
  const handleResetFilters = useCallback(
    (filtersList) => {
      resetFilters();
      setPage(TABLE_FIRST_PAGE);
      resetQueryParamsWithDefaults(filtersList);
    },
    [resetFilters, setPage],
  );

  return {
    params,
    filters,
    setFilter: handleFilterChange,
    removeFilter: handleRemoveFilter,
    resetFilters: handleResetFilters,
    page,
    setLimit,
    limit,
    setPage,
    skip,
    setSkip,
    sorting,
    setSorting,
  };
}
