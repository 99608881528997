import { ActionsCell } from '../TableBody/ActionsCell/ActionsCell';
import { RenderActionColumnCellParams } from '../types';

export const renderActionColumnCell = <Data extends object>({
  rowId,
  previousValues,
  onSaveHandler,
  onCancelEditingHandler,
  additionalRowActions,
  values,
  columns,
  isEditedRow,
  order,
  hideEdit,
}: RenderActionColumnCellParams<Data>) => {
  const wrappedOnSaveHandler = (updatedValues: Data) =>
    onSaveHandler(previousValues, updatedValues);

  const wrappedOnCancelEditingHandler = (updatedValues: Data) =>
    onCancelEditingHandler(previousValues, updatedValues);

  return (
    <ActionsCell<Data>
      rowId={rowId}
      onSaveRow={onSaveHandler ? wrappedOnSaveHandler : null}
      onCancelEditingRow={
        onCancelEditingHandler ? wrappedOnCancelEditingHandler : null
      }
      additionalActions={additionalRowActions}
      values={values}
      columns={columns}
      isEditedRow={isEditedRow}
      order={order}
      hideEdit={hideEdit}
    />
  );
};
