export const DISPLAYED_COLUMN_KEYS = [
  'stepGroup',
  'author',
  'update_time',
  'status',
  'comment',
  'actions',
];
export const INFO_DATE_VALUES = ['gwTime', 'valueDate', 'created', 'updated'];
export const INFO_NUMBER_VALUES = [
  'amount',
  'price',
  'quantity',
  'management_fee',
  'setup_owner',
  'value',
];
export const CURRENT_STATE_DATE_VALUES = ['valueDate'];
export const CURRENT_STATE_NUMBER_VALUES = ['cross_rate', 'market_value'];
export const INPUT_DATE_VALUES = ['created', 'updated'];
export const INPUT_NUMBER_VALUES = ['management_fee', 'setup_owner', 'value'];
export const INCLUDED_KEYS = [
  'accountId',
  'accountPurpose',
  'accountType',
  'accruedInterest',
  'amount',
  'asset',
  'brokerAccountId',
  'brokerClientId',
  'comment',
  'counterparty',
  'cross_rate',
  'currency',
  'executionSchemeId',
  'gwTime',
  'internalComment',
  'market_value',
  'operationType',
  'price',
  'processAccruedInterest',
  'redemption',
  'quantity',
  'schedule',
  'settlementBrokerAccountId',
  'settlementBrokerClientId',
  'settlementCounterparty',
  'side',
  'symbolId',
  'symbolIdOverride',
  'tradeType',
  'useAutoCashConversion',
  'user',
  'useRevenue',
  'valueDate',
];
export const TRANSACTION_CHARGE_FEE_KEYS = [
  'accountId',
  'accountPurpose',
  'accountType',
  'amount',
  'asset',
  'comment',
  'cross_rate',
  'gwTime',
  'internalComment',
  'market_value',
  'operationType',
  'price',
  'settlementCounterparty',
  'symbolId',
  'useAutoCashConversion',
  'user',
  'useRevenue',
];
export const TRADE_KEYS = [
  'accountId',
  'accountPurpose',
  'accountType',
  'accruedInterest',
  'brokerAccountId',
  'brokerClientId',
  'comment',
  'counterparty',
  'cross_rate',
  'gwTime',
  'currency',
  'executionSchemeId',
  'internalComment',
  'market_value',
  'price',
  'processAccruedInterest',
  'redemption',
  'quantity',
  'settlementBrokerAccountId',
  'settlementBrokerClientId',
  'settlementCounterparty',
  'side',
  'symbolId',
  'symbolIdOverride',
  'tradeType',
  'user',
  'valueDate',
];
export const FEE_SETUP_KEYS = [
  'account_id',
  'name',
  'feed_type',
  'value',
  'management_fee',
  'setup_owner',
  'created',
  'updated',
];
export const FEE_SETUP_GET = [];
export const FEE_SETUP_USER = [
  'client',
  'asset_manager',
  'fee_setup',
  'second_side_doc',
  'created',
  'updated',
];
