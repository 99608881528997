import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import { initResources } from './resources';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { ComposedThemeProvider } from './theme/ComposedThemeProvider';
import { addBaseTag } from './utils';

addBaseTag();
initResources();

window.WORKFLOW_UI = {
  ...(window.WORKFLOW_UI || {}),
  render: (node: Element) => {
    ReactDOM.render(
      <Provider store={store}>
        <ComposedThemeProvider>
          <App />
        </ComposedThemeProvider>
      </Provider>,
      node,
    );
  },
};

const workflowUiDiv = document.getElementById('workflow-ui');
if (workflowUiDiv) {
  ReactDOM.render(
    <Provider store={store}>
      <ComposedThemeProvider>
        <App />
      </ComposedThemeProvider>
    </Provider>,
    workflowUiDiv,
  );
}

serviceWorker.unregister();
